import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { ElectricEnergyDto } from 'app/models/electricEnergy/electricEnergyDto';
import { FilterConstants } from 'app/constants/filterConstants';
import { ExtraParamsDto } from 'app/models/ExtraParameters/extraParamsDto';
import { StackedElectricEnergyDto } from 'app/models/electricEnergy/stackedElectricEnergyDto';
import { StackedNaturalGasDto } from 'app/models/electricEnergy/stackedNaturalGasDto';
import { StackedExtraParamsDto } from 'app/models/ExtraParameters/stackedExtraParamsDto';
import { StackedMonthlyElectricEnergyDto } from 'app/models/electricEnergy/stackedMonthlyElectricEnergyDto';
import { StackedMonthlyNaturalGasDto } from 'app/models/electricEnergy/stackedMonthlyNaturalGasDto';
import { MonthlyStackedExtraParamsDto } from 'app/models/ExtraParameters/monthlyStackedExtraParamsDto';
import { Colours } from 'app/constants/colors';


@Component({
  selector: 'app-stacked-monthly-data-energy-chart',
  templateUrl: './stacked-monthly-data-energy-chart.component.html',
  styleUrls: ['./stacked-monthly-data-energy-chart.component.scss']
})
export class StackedMonthlyDataEnergyChartComponent implements OnInit {

  @Input() isGas;
  @Input() extraParamsList;
  @Input() isBothCommodity;
  @Input() inputData;
  @Input() showMoreProfiles;
  chartOption: EChartsOption;
  myChart: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (!changes.currentValue) {
    //   return;
    // }
    let changedData = changes.inputData.currentValue
    let showProcent: boolean = false;
    let calculatedSeries = [];
    let dateDict = {};

    let categories: string[] = [];


    if (this.isBothCommodity) {
      let data;
      data = changedData["Gaz"]
      // if (typeof (data) != "object") {
      //   return;
      // }
      data.forEach((element: StackedMonthlyNaturalGasDto) => {
        let productDict = {};
        element.monthlyNaturalGases.forEach(entry => {
          if (Array.isArray(this.formatDayMonth(entry.date))) {
            dateDict[this.formatDayMonth(entry.date)].push(entry)
          }
          else {
            dateDict[this.formatDayMonth(entry.date)] = [entry]
          }
          if (Array.isArray(productDict[entry.product])) {
            productDict[entry.product].push(entry);
          }
          else {
            productDict[entry.product] = [entry]
          }
        })
        const copyDict = productDict;

        Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
          productDict[key] = {};

          value.forEach(element => {
            if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
              productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
            }
            else {
              productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
            }
          });

        })

        Object.entries(productDict).forEach(([key, value]) => {
          let category: string;
          Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
            category = `${key} ${marketKey}`;
            calculatedSeries.push(
              {
                name: `${key} ${marketKey} Anul ${element.year}`,
                type: 'line',
                axisLabel: {
                  fontFamily: 'Arial',
                  color: '#595959'
                },
                data: marketValue.map(x => [this.formatDayMonth(x.date), x.indexValue])
              },
            )
          });

        });
      });

      data = changedData["Energie"]
      // if (typeof (data) != "object") {
      //   return;
      // }
      data.forEach((element: StackedMonthlyElectricEnergyDto) => {
        let productDict = {};


        element.monthlyElectricEnergies.forEach(entry => {
          if (Array.isArray(this.formatDayMonth(entry.date))) {
            dateDict[this.formatDayMonth(entry.date)].push(entry)
          }
          else {
            dateDict[this.formatDayMonth(entry.date)] = [entry]
          }
          if (Array.isArray(productDict[entry.product])) {
            productDict[entry.product].push(entry);
          }
          else {
            productDict[entry.product] = [entry]
          }
        })
        const copyDict = productDict;

        Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
          productDict[key] = {};

          value.forEach(element => {
            if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
              productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
            }
            else {
              productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
            }
          });

        })

        Object.entries(productDict).forEach(([key, value]) => {
          let category: string;
          Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
            category = `${key} ${marketKey}`;
            calculatedSeries.push(
              {
                name: `${key} ${marketKey} Anul ${element.year}`,
                type: 'line',
                axisLabel: {
                  fontFamily: 'Arial',
                  color: '#595959'
                },
                data: marketValue.map(x => [this.formatDayMonth(x.date), x.chartValue])
              },
            )
          });

        });
      });
    }
    else {

      let data;
      if (this.isGas) {
        data = changedData["Gaz"]
        // if (typeof (data) != "object") {
        //   return;
        // }
        data.forEach((element: StackedMonthlyNaturalGasDto) => {
          let productDict = {};


          element.monthlyNaturalGases.forEach(entry => {
            if (Array.isArray(this.formatDayMonth(entry.date))) {
              dateDict[this.formatDayMonth(entry.date)].push(entry)
            }
            else {
              dateDict[this.formatDayMonth(entry.date)] = [entry]
            }
            if (Array.isArray(productDict[entry.product])) {
              productDict[entry.product].push(entry);
            }
            else {
              productDict[entry.product] = [entry]
            }
          })
          const copyDict = productDict;

          Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
            productDict[key] = {};

            value.forEach(element => {
              if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
                productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
              }
              else {
                productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
              }
            });

          })

          Object.entries(productDict).forEach(([key, value]) => {
            let category: string;
            Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
              category = `${key} ${marketKey}`;
              calculatedSeries.push(
                {
                  name: `${key} ${marketKey} Anul ${element.year}`,
                  type: 'line',
                  axisLabel: {
                    fontFamily: 'Arial',
                    color: '#595959'
                  },
                  data: marketValue.map(x => [this.formatDayMonth(x.date), x.indexValue])
                },
              )
            });

          });
        });
      }
      else {
        data = changedData["Energie"]
        // if (typeof (data) != "object") {
        //   return;
        // }
        data.forEach((element: StackedMonthlyElectricEnergyDto) => {
          let productDict = {};


          element.monthlyElectricEnergies.forEach(entry => {
            if (Array.isArray(this.formatDayMonth(entry.date))) {
              dateDict[this.formatDayMonth(entry.date)].push(entry)
            }
            else {
              dateDict[this.formatDayMonth(entry.date)] = [entry]
            }
            if (Array.isArray(productDict[entry.product])) {
              productDict[entry.product].push(entry);
            }
            else {
              productDict[entry.product] = [entry]
            }
          })
          const copyDict = productDict;

          Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
            productDict[key] = {};

            value.forEach(element => {
              if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
                productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
              }
              else {
                productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
              }
            });

          })

          Object.entries(productDict).forEach(([key, value]) => {
            let category: string;
            Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
              category = `${key} ${marketKey}`;
              calculatedSeries.push(
                {
                  name: `${key} ${marketKey} Anul ${element.year}`,
                  type: 'line',
                  axisLabel: {
                    fontFamily: 'Arial',
                    color: '#595959'
                  },
                  data: marketValue.map(x => [this.formatDayMonth(x.date), x.chartValue])
                },
              )
            });
          });
        });
      }

    }

    if (changedData["ExtraParamsList"].length > 0) {
      FilterConstants.extraParamsList.forEach(param => {
        changedData["ExtraParams"].forEach((element: MonthlyStackedExtraParamsDto) => {
          if (changedData["ExtraParamsList"].includes(param.id)) {
            const category = `${param.name} Anul ${element.year}`
            categories.push(category);
            let seriesToAdd = {
              name: category,
              axisLabel: {
                fontFamily: 'Arial',
                color: '#595959'
              },
              type: 'line',
              data: [],
              yAxisIndex: 0,
            };
            switch (param.id) {
              case 1:
                seriesToAdd.data = element.monthlyExtraParameters.map((x: ExtraParamsDto) => [this.formatDayMonth(x.date), x.certificateEmisiiCo2])
                break;
              case 2:
                seriesToAdd.data = element.monthlyExtraParameters.map((x: ExtraParamsDto) => [this.formatDayMonth(x.date), x.petrol])
                break;
              case 3:
                seriesToAdd.data = element.monthlyExtraParameters.map((x: ExtraParamsDto) => [this.formatDayMonth(x.date), x.umplereLacuriAcumulare * 100])
                seriesToAdd.yAxisIndex = 1;
                showProcent = true;
                break;
              case 4:
                seriesToAdd.data = element.monthlyExtraParameters.map((x: ExtraParamsDto) => [this.formatDayMonth(x.date), x.inmagazinareGazeNaturale * 100])
                seriesToAdd.yAxisIndex = 1;
                showProcent = true;
                break;
              default:
            }
            calculatedSeries.push(seriesToAdd);
          }

        });
      })
    }
    let yaxys: echarts.YAXisComponentOption[] = [
      {
        type: 'value',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      }]
    if (showProcent) {
      yaxys.push(
        {
          type: 'value',
          min: 0,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959',
            formatter: '{value} %'

          },
          max: 100,
          position: 'right',
          axisLine: {
            show: true,
          },
        },
      )
    }
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '1%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: calculatedSeries.map(entry => entry.name)
      },
      toolbox: {
        feature: {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: 'Grafic'
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "20%",
        containLabel: true
      },
      dataZoom: [
        {
          left: "7%",
          right: "10%",
          bottom: 40,
          textStyle: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          backgroundColor: '#B5F1FF',
          dataBackground: {
            lineStyle: {
              color: '#003A47'
            },
            // areaStyle: {
            //   color: '#00758F'
            // }
          },
          borderColor: '#003A47',
          fillerColor: '#47C6A3'
        },
        {
          type: "inside"
        }
      ],
      xAxis: [
        {
          type: 'category',
          nameTextStyle: {
            fontFamily: "Arial"
          },
          boundaryGap: true,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          // data: changedData.map(x => x.date.split("T")[0])
          data: Object.keys(dateDict).sort(this.compareItems)
        }
      ],
      yAxis: yaxys,
      series: calculatedSeries,
      color: Colours.ColorPallete
    };


  }

  private formatDayMonth(input: string) {
    return input.substring(5, 7);
  }

  private compareItems(item1, item2) {
    const item1Day = item1.split("-")[0]
    const item1Month = item1.split("-")[1]
    const item2Day = item2.split("-")[0]
    const item2Month = item2.split("-")[1]

    if (item1Month < item2Month) {
      return -1;
    }
    if (item1Month > item2Month) {
      return 1;
    }
    if (item1Month == item2Month) {
      if (item1Day < item2Day) {
        return -1
      }
      if (item1Day < item2Day) {
        return 1
      }
    }
    return 0;
  }
}
