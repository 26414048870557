import { EnumModel } from "app/models/scannergFilterModels/enumModel";

export class FilterConstants {
    public static readonly commodityList: EnumModel[] = [
        { id: 1, name: "Energie electrica" },
        { id: 2, name: "Gaze naturale" }
    ]
    public static readonly deliveryTypeList: EnumModel[] = [
        { id: 1, name: "Livrare viitoare la termen" },
        { id: 2, name: "Livrare ziua urmatoare" }
    ]
    public static readonly deliveryProfileList: EnumModel[] = [
        { id: 1, name: "Banda" },
        { id: 2, name: "Varf" },
        { id: 3, name: "Gol" },
    ]
    public static readonly platformList: EnumModel[] = [
        { id: 1, name: "Locala" },
        { id: 2, name: "Externa" },
    ]
    public static readonly marketList: EnumModel[] = [
        { id: 1, name: "Negociere dublă continuă (locală, energie electrică)" },
        { id: 2, name: "Licitație extinsă (locală, energie electrică)" },
        { id: 3, name: "Negociere continua (locală, energie electrică)" },
        { id: 4, name: "Standard (locală, gaze naturale)" },
        { id: 5, name: "TTF (externă, gaze naturale)" },
        { id: 6, name: "Spot" },
        { id: 7, name: "PZU" },
        { id: 8, name: "EEX Germania (externă, energie electrică)" },
        { id: 9, name: "Contraparte centrală (locală, gaze naturale)" },
    ]
    public static readonly extraParamsList: EnumModel[] = [
        { id: 1, name: "Preț certificate emisii CO2" },
        { id: 2, name: "Preț petrol" },
        { id: 3, name: "Grad umplere lacuri acumulare România" },
        { id: 4, name: "Grad înmagazinare gaze naturale România" },
    ]
    public static readonly pzuIntervalList: EnumModel[] = [
        { id: 1, name: "Varf" },
        { id: 2, name: "Gol" },
    ]
}