import { Component, OnInit } from '@angular/core';
import { StartEndDateModel } from 'app/models/scannergFilterModels/startEndDateModel';
import { ExtraParamsService } from 'app/services/extra-params.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-prices-co2',
  templateUrl: './scannerg-prices-co2.component.html',
  styleUrls: ['./scannerg-prices-co2.component.scss']
})
export class ScannergPricesCo2Component extends LoadableComponentBase implements OnInit {

  filters: StartEndDateModel;
  data;
  loading: boolean = false;

  constructor(private extraParamService: ExtraParamsService) {
    super();
  }
  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.extraParamService
      .getAllExtraParamsDaily(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries.filter(entry => ((entry.certificateEmisiiCo2 != 0) && (entry.certificateEmisiiCo2 != null)));
        this.loading = false;
      });
  }
}
