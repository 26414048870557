import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trainerg-landing',
  templateUrl: './trainerg-landing.component.html',
  styleUrls: ['./trainerg-landing.component.scss']
})
export class TrainergLandingComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("trainerg-i")
  }

  onLinkedInIcon() {
    window.open("https://www.linkedin.com/company/partnergi", '_blank');
  }
}
