import { Component, OnInit } from '@angular/core';
import { LegalPressService } from 'app/services/legal-press.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-legal',
  templateUrl: './scannerg-legal.component.html',
  styleUrls: ['./scannerg-legal.component.scss']
})
export class ScannergLegalComponent extends LoadableComponentBase implements OnInit {

  fields;

  constructor(private legalPressService: LegalPressService) {
    super(); 
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.legalPressService
      .getAllLegalPress()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.fields = entries;
      });
  }

}
