import { Component, Input, OnInit } from "@angular/core";
import { Colours } from "app/constants/colors";
import { EnergyTimeButtonsFilter } from "app/models/scannergFilterModels/energyFilterTimeButtonsModel";
import { ElectricEnergyService } from "app/services/electric-energy.service";
import { LoadableComponentBase } from "app/shared/components/ag-grid/LoadableComponentBase";
import { EChartsOption } from "echarts";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-scannerg-prices-element-echart",
  templateUrl: "./scannerg-prices-element-echart.component.html",
  styleUrls: ["./scannerg-prices-element-echart.component.scss"],
})
export class ScannergPricesElementEchartComponent extends LoadableComponentBase implements OnInit {

  @Input() productName;
  filters: EnergyTimeButtonsFilter;
  energyDictData = { Volum: [], BidPrice: [], AskPrice: [], Date: [] };
  data;
  period = 1;
  chartOption: EChartsOption;
  constructor(private energyService: ElectricEnergyService) {
    super();
  }

  ngOnInit(): void {
    this.loadOneMonthData();
  }

  private initChart() {
    let series = [];
    let dateDict = {};
    this.data.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    series.push(
      {
        name: `Preț local tranzacționare`,
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.data.map(x => [moment(x.date).format('DD-MM-YYYY'), x.transactionPrice == 0 ? null : x.transactionPrice])
      },
    )
    // series.push(
    //   {
    //     name: `Volum local tranzacționat`,
    //     type: 'bar',
    //     yAxisIndex: 1,
    //     axisLabel: {
    //       fontFamily: 'Arial',
    //       color: '#595959'
    //     },
    //     data: this.data.map(x => [moment(x.date).format('DD-MM-YYYY'), x.volume == 0 ? null : x.volume])
    //   },
    // )
    series.push(
      {
        name: `Cotație locală vânzare`,
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.data.map(x => [moment(x.date).format('DD-MM-YYYY'), x.bestAsk == 0 ? null : x.bestAsk])
      },
    )
    series.push(
      {
        name: `Cotație locală cumpărare`,
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.data.map(x => [moment(x.date).format('DD-MM-YYYY'), x.bestBid == 0 ? null : x.bestBid])
      },
    )
    series.push(
      {
        name: `Preț extern închidere piață`,
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.data.map(x => [moment(x.date).format('DD-MM-YYYY'), x.externValue == 0 ? null : x.externValue])
      },
    )

    this.chartOption = {
      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: series.map(entry => entry.name)
      },
      toolbox: {
        feature: {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: this.productName
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort((a, b) => moment(a).diff(moment(b))).map(x => moment(x).format('DD-MM-YYYY'))
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Preț',
          position: 'right',
          nameTextStyle: {
            fontFamily: "Arial"
          },
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          axisLine: {
            show: true,
          },
          offset: 10
        },
        {
          type: 'value',
          name: 'Volum',
          position: 'left',
          nameTextStyle: {
            fontFamily: "Arial"
          },
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          offset: 10,
          axisLine: {
            show: true,
          }
        }
      ],
      series: series,
      color: Colours.ColorPallete
    };
  }

  public loadOneMonthData() {
    this.filters = {
      product: this.productName,
      fromDate: -1,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.period = 1;
        this.initChart();
      });
  }

  public loadThreeMonthsData() {
    this.filters = {
      product: this.productName,
      fromDate: -3,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.period = 3;
        this.initChart();
      });
  }

  public loadOneYearData() {
    this.filters = {
      product: this.productName,
      fromDate: -12,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.period = 12;
        this.initChart();
      });
  }
  public loadTwoYearsData() {
    this.filters = {
      product: this.productName,
      fromDate: -24,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.period = 24;
        this.initChart();
      });
  }
}
