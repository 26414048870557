import { Component, OnInit } from '@angular/core';
import { PzuEnergyFiltersModel } from 'app/models/scannergFilterModels/pzuEnergyFiltersModel';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-prices-electric-energy-echilibrare',
  templateUrl: './scannerg-prices-electric-energy-echilibrare.component.html',
  styleUrls: ['./scannerg-prices-electric-energy-echilibrare.component.scss']
})
export class ScannergPricesElectricEnergyEchilibrareComponent extends LoadableComponentBase implements OnInit {

  filters: PzuEnergyFiltersModel;
  data;
  loading: boolean = false;

  constructor(private energyService: ElectricEnergyService) {
    super();
  }
  ngOnInit(): void {
    this.loadData();
  }
  
  private loadData() {
    this.loading = true;
    this.energyService
      .getEnergyMonthlyQuotesDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.loading = false;
      });
  }
}
