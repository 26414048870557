import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})

export class NotFoundComponent implements OnInit {
  constructor() { }

  ngOnInit() { }

}
