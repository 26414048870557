import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { SigninComponent } from './examples/signin/signin.component';
import { ForgotPasswordComponent } from './examples/forgot-password/forgot-password.component';
import { ContactComponent } from './examples/contact/contact.component';
import { BlogPostComponent } from './examples/blog-page/blog-post/blog-post.component';
import { NotFoundComponent } from './examples/404/404.component';
import { EventsComponent } from './examples/events/events/events.component';
import { LayoutDefaultNavbarComponent } from './shared/components/layout-default-navbar/layout-default-navbar.component';
import { LayoutScannergComponent } from './shared/components/layout-scannerg/layout-scannerg.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LayoutAuthenticationComponent } from './shared/components/layout-authentication/layout-authentication.component';
import { ScannergEssentialComponent } from './examples/scannerg/scannerg-essential/scannerg-essential.component';
import { ScannergPricesComponent } from './examples/scannerg/scannerg-prices/scannerg-prices.component';
import { ScannergVolumesComponent } from './examples/scannerg/scannerg-volumes/scannerg-volumes.component';
import { ScannergProvidersComponent } from './examples/scannerg/scannerg-providers/scannerg-providers.component';
import { ScannergLegalComponent } from './examples/scannerg/scannerg-legal/scannerg-legal.component';
import { ScannergAdvancedComponent } from './examples/scannerg/scannerg-advanced-components/advanced/scannerg-advanced.component';
import { RoleType } from './constants/role-types';
import { ManageClientPageComponent } from './examples/manage/clients-page/clients-page.component';
import { LayoutManageComponent } from './shared/components/layout-manage/layout-manage.component';
import { ManagePressAndLegalPageComponent } from './examples/manage/admin-press-legal-page/admin-press-legal-page.component';
import { EuropePzuValuesComponent } from './examples/europe-pzu-values/europe-pzu-values.component';
import { ResetPasswordComponent } from './examples/reset-password/reset-password.component';
import { ScannergSolutionComponent } from './examples/scannerg-solution/scannerg-solution.component';
import { SolutionsComponent } from './examples/solutions/solutions.component';
import { LayoutTransparentScannergComponent } from './shared/components/layout-transparent-scannerg/layout-transparent-scannerg.component';
import { PlannergLandingComponent } from './examples/plannerg-landing/plannerg-landing.component';
import { GreenergLandingComponent } from './examples/greenerg-landing/greenerg-landing.component';
import { TrainergLandingComponent } from './examples/trainerg-landing/trainerg-landing.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LayoutAuthenticationComponentSignIn } from './shared/components/layout-authentication-signin/layout-authentication-signin.component';

const routes: Routes = [
  // {
  //   path: "",
  //   component: LayoutScannergComponent,
  //   children: [
  //     // { path: 'landing', component: ComponentsComponent },
  //     // { path: 'user-profile', component: ProfileComponent },
  //     { path: '', component: ScannergEssentialComponent },
  //     // { path: 'nucleoicons', component: NucleoiconsComponent },
  //     // { path: 'about-us', component: EuropePzuValuesComponent },
  //     // { path: 'about-us', component: AboutUsComponent },
  //     // { path: 'test', component: ComponentsComponent },
  //   ]
  // },
  {
    path: '', redirectTo: 'essential', pathMatch: 'full'
  },
  {
    path: "plannerg-i",
    // component: LayoutComponent,
    children: [
      { path: '', component: PlannergLandingComponent },
    ]
  },
  {
    path: "greenerg-i",
    // component: LayoutComponent,
    children: [
      { path: '', component: GreenergLandingComponent },
    ]
  },
  {
    path: "trainerg-i",
    // component: LayoutComponent,
    children: [
      { path: '', component: TrainergLandingComponent },
    ]
  },
  // {
  //   path: "",
  //   component: LayoutDefaultNavbarComponent,
  //   children: [
  //     { path: 'contact', component: ContactComponent },
  //     // { path: 'signup', component: ContactComponent },
  //     { path: 'events', component: EventsComponent },
  //     { path: 'solutii', component: SolutionsComponent },
  //   ]
  // },
  {
    path: "",
    component: LayoutAuthenticationComponent,
    children: [
      { path: 'signup', component: SignupComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
    ]
  },
  {
    path: "",
    component: LayoutAuthenticationComponentSignIn,
    children: [
      { path: 'signin', component: SigninComponent },
    ]
  },
  {
    path: 'scannerg', redirectTo: 'essential', pathMatch: 'full'
  },
  {
    path: 'scannerg/esential', redirectTo: 'essential', pathMatch: 'full'
  },  
  {
    path: 'scannerg/preturi', redirectTo: 'prices', pathMatch: 'full'
  },
  {
    path: 'scannerg/avansat', redirectTo: 'analysis', pathMatch: 'full'
  },
  {
    path: 'scannerg/volume', redirectTo: 'volumes', pathMatch: 'full'
  },
  {
    path: 'scannerg/furnizori', redirectTo: 'suppliers', pathMatch: 'full'
  },
  {
    path: "",
    component: LayoutScannergComponent,
    children: [
      { path: 'avansat', redirectTo: 'analysis', pathMatch: 'full' },
      { path: 'esential', redirectTo: 'essential', pathMatch: 'full' },
      { path: 'preturi', redirectTo: 'prices', pathMatch: 'full' },
      { path: 'volume', redirectTo: 'volumes', pathMatch: 'full' },
      { path: 'furnizori', redirectTo: 'suppliers', pathMatch: 'full' },
      {
        path: 'analysis', component: ScannergAdvancedComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Trial },
      },
      {
        path: 'essential', component: ScannergEssentialComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Trial },
      },
      {
        path: 'prices', component: ScannergPricesComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Trial },
      },
      {
        path: 'volumes', component: ScannergVolumesComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Trial },
      },
      {
        path: 'suppliers', component: ScannergProvidersComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Trial },
      },
      // {
      //   path: 'presa', component: ScannergLegalComponent,
      //   canActivate: [AuthGuard],
      //   data: { minRole: RoleType.Trial },
      // },
      // {
      //   path: 'pzu-europa', component: EuropePzuValuesComponent,
      // },
    ]
  }, {
    path: "manage",
    component: LayoutManageComponent,
    children: [
      { path: '', redirectTo: 'users', pathMatch: 'full' },
      {
        path: 'users', component: ManageClientPageComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Admin },
      },
      {
        path: 'press-and-legal', component: ManagePressAndLegalPageComponent,
        canActivate: [AuthGuard],
        data: { minRole: RoleType.Admin },
      },
    ]
  },
  {
    path: '**', pathMatch: 'full',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
