export class Colours {
    public static readonly ColorPallete =
        [
            '#00758F',
            '#47C6A3',
            '#EDD59E',
            '#CE5A83',
            '#70C6EC',
            '#8FB737',
            '#7374B1',
            '#6CE3FF',
            '#47C6A3',
            '#F7EED8',
            '#B5E8D9',
            '#C5E8F7',
            '#D3E5AB',
            '#C4C4DE',
            '#003A47',
            '#72223E',
            '#A77D1F',
            '#206753',
            '#14709A',
            '#475B1B',
            '#38396A'
        ]
}
