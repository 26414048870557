import { Component, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { EnergyTimeButtonsFilter } from 'app/models/scannergFilterModels/energyFilterTimeButtonsModel';
import { takeUntil } from 'rxjs/operators';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { NaturalGasMonthlyQuotes } from 'app/models/electricEnergy/monthlyGasQuotes';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { Colours } from 'app/constants/colors';
import * as moment from 'moment';


@Component({
  selector: 'scannerg-volumes-natural-gas-import-export',
  templateUrl: './scannerg-volumes-natural-gas-import-export.component.html',
  styleUrls: ['./scannerg-volumes-natural-gas-import-export.component.scss']
})
export class ScannergVolumesNaturalGasImportExportComponent extends GridComponentBase<NaturalGasMonthlyQuotes> implements OnInit {

  chartOption: EChartsOption;
  myChart: any;
  chartData: NaturalGasMonthlyQuotes[];
  period: number = 12;
  filters: EnergyTimeButtonsFilter;

  constructor(private gasService: NaturalGasService) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T").join(" "),
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('MM-YYYY')
        }
      },
      {
        valueGetter: x => x.data.volumImport.toFixed(2),
        headerName: "Volum Import",
        width: 450,
        suppressSizeToFit: true,
      },
      {
        valueGetter: x => x.data.volumExport.toFixed(2),
        headerName: "Volum Export",
        width: 450,
        suppressSizeToFit: true,
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
    this.loadOneYearData();
  }

  private initChart() {
    let series = [];
    let dateDict = {};
    this.chartData.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0].substring(0, 7)])) {
        dateDict[entry.date.split("T")[0].substring(0, 7)].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0].substring(0, 7)] = [entry]
      }
    })
    series.push(
      {
        name: `Volum import`,
        type: 'bar',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.chartData.map(x => [moment(x.date).format('MM-YYYY'), x.volumImport])
      },
    )
    series.push(
      {
        name: `Volum export`,
        type: 'bar',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: this.chartData.map(x => [moment(x.date).format('MM-YYYY'), x.volumExport])
      },
    )
    this.chartOption = {
      title: {
        text: "Import-export gaze naturale",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        }
      },
      tooltip: this.period == 1 ? {
        trigger: "item",
      } : {
        trigger: "axis",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: [
          "Volum export",
          "Volum import",
        ],
      },
      toolbox: {
        top: 0,
        feature: this.period == 1 ? {} : {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: "Volum import-export gaze naturale"
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: this.period == 1 ? [] : [
        {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(x => moment(x).format('MM-YYYY'))
        },
      ],
      yAxis: this.period == 1 ? [] : [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          position: 'right',
          axisLine: {
            show: true,
          },
        },
      ],
      series: series,
      color: Colours.ColorPallete
    };
  }

  public loadOneMonthData() {
    this.filters = {
      product: "",
      fromDate: -10,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.period = 1;
        this.chartData = entries;
        this.initChart();
      });
  }

  public loadThreeMonthsData() {
    this.filters = {
      product: "",
      fromDate: -3,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.period = 3;
        this.chartData = entries;
        this.initChart();
      });
  }

  public loadOneYearData() {
    this.filters = {
      product: "",
      fromDate: -12,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.chartData = entries;
        this.period = 12;
        this.data = entries;
        this.initChart();
      });
  }
  public loadTwoYearsData() {
    this.filters = {
      product: "",
      fromDate: -24,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.chartData = entries;
        this.data = entries;
        this.period = 24;
        this.initChart();
      });
  }

  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
