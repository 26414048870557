import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import * as europeMap from './custom1geo.json';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { SelfUnsubscriberBase } from 'app/shared/components/base/SelfUnsubscriberBase';
import { EuropePzuModel } from 'app/models/electricEnergy/europePzuModel';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard'
import { Router } from '@angular/router';

@Component({
  selector: 'europe-pzu-values',
  templateUrl: './europe-pzu-values.component.html',
  styleUrls: ['./europe-pzu-values.component.scss']
})
export class EuropePzuValuesComponent extends SelfUnsubscriberBase implements OnInit {

  mapChartOption: EChartsOption;
  data: EuropePzuModel[];
  loading: boolean = false;
  min = 0;
  max = 1000;

  constructor(private energyService: ElectricEnergyService,
    private _clipboardService: ClipboardService,
    private router: Router) {
    super();
  }
  private initChart() {
    echarts.registerMap('EU', europeMap["default"]);
    var europeData = [];
    this.data.forEach(element => {
      europeData.push({
        name: element.zone,
        value: element.price
      })
    });
    this.mapChartOption = {
      title: {
        text: `${moment(this.data[0].date).format("DD-MM-YYYY")}`,
        textStyle: {
          fontFamily: 'Arial',
          color: '#47C6A3',
          fontSize: '32'
        },
        left: "center",
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontFamily: 'Arial'
        },
        formatter: '{b}<br/>{c} EUR/MWh'
      },
      visualMap: {
        min: this.min,
        max: this.max,
        top: '25',
        right: 0,
        // text: [`${this.max}`, `${this.min}`],
        realtime: false,
        calculable: true,
        textStyle: {
          fontFamily: "Arial"
        },
        inRange: {
          color: ['#8fb737', '#edd59e', '#47C6A3']
        }
      },
      series: [
        {
          name: 'Europa',
          type: 'map',
          map: 'EU',
          nameProperty: 'iso_a2',
          label: {
            show: false,
            fontFamily: 'Arial',
          },
          emphasis: {
            itemStyle: {
              // Color in emphasis state.
              areaColor: '#00758f'
            }

          },
          data: europeData
        }
      ]
    }
  }
  ngOnInit(): void {
    this.loadData();

  }

  private loadData() {
    this.loading = true;
    this.energyService
      .getEuropePzu()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.min = entries.sort(this.compareAsc)[0].price;
        this.max = entries.sort(this.compareDesc)[0].price;
        this.initChart();
        this.loading = false;
      });
  }

  copy() {
    this._clipboardService.copy(window.location.href)
  }

  compareAsc(a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  compareDesc(a, b) {
    if (a.price > b.price) {
      return -1;
    }
    if (a.price < b.price) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
}