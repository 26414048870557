import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from 'app/components/modal/modal.component';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { EventInfoModalContent } from '../modals/event-modal-content/event-modal-content.component';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit {
    event: any
    constructor(private modalService: NgbModal) {
    }
    ngOnInit() { }

    openModal() {
        const modalRef = this.modalService.open(EventInfoModalContent);
        modalRef.componentInstance.name = 'World';
    }
}
