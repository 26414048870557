import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-scannerg-prices',
  templateUrl: './scannerg-prices.component.html',
  styleUrls: ['./scannerg-prices.component.scss']
})
export class ScannergPricesComponent implements OnInit {

  constructor(      private titleService: Title
    ) {
  }
  ngOnInit(): void {
    this.titleService.setTitle("partenerg | Prețuri")
  }

}
