import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordDto } from 'app/models/user/forgotPasswordDto';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    focus;
    test: Date = new Date();
    public forgotPasswordForm: FormGroup
    public successMessage: string;
    public errorMessage: string;
    public showSuccess: boolean = false;;
    public showError: boolean;
    constructor(private _authService: AuthenticationService) { }

    ngOnInit(): void {
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl("", [Validators.required])
        })
    }

    public validateControl = (controlName: string) => {
        return this.forgotPasswordForm.controls[controlName].invalid && this.forgotPasswordForm.controls[controlName].touched
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.forgotPasswordForm.controls[controlName].hasError(errorName)
    }

    public forgotPassword = (forgotPasswordFormValue) => {
        this.showError = this.showSuccess = false;
        const forgotPass = { ...forgotPasswordFormValue };
        const forgotPassDto: ForgotPasswordDto = {
            email: forgotPass.email,
            clientURI: 'https://www.data.partenerg.ro/resetpassword'
        }
        this._authService.forgotPassword(forgotPassDto)
            .subscribe(_ => {
                this.showSuccess = true;
                this.successMessage = 'The link has been sent, please check your email to reset your password.'
            },
                err => {
                    this.showError = true;
                    this.errorMessage = err;
                })
    }

}
