import { Injectable } from "@angular/core";
import { ElectricEnergyDto } from "app/models/electricEnergy/electricEnergyDto";
import { ElectricEnergyChartDto } from "app/models/electricEnergy/electricEnergyPricesChartDto";
import { electricEnergyProductDto } from "app/models/electricEnergy/electricEnergyPricesProductDto";
import { EuropePzuModel } from "app/models/electricEnergy/europePzuModel";
import { ElectricEnergyMonthlyQuotes } from "app/models/electricEnergy/monthlyEnergyQuotes";
import { MonthlyElectricEnergyQuotesDto } from "app/models/electricEnergy/monthlyElectricEnergyQuotesDto";
import { PzuElectricEnergyDto } from "app/models/electricEnergy/pzuElectricEnergyDto";
import { StackedElectricEnergyDto } from "app/models/electricEnergy/stackedElectricEnergyDto";
import { StackedMonthlyElectricEnergyDto } from "app/models/electricEnergy/stackedMonthlyElectricEnergyDto";
import { StackedPzuElectricEnergyDto } from "app/models/electricEnergy/stackedPzuElectricEnergyDto";
import { FutureDeliveryMonitoringModel } from "app/models/monitoring/futureDeliveryMonitoringModel";
import { ProducersModel } from "app/models/producers/producersModel";
import { ProvidersModel } from "app/models/providers/providersModel";
import { DailyDataFiltersModel } from "app/models/scannergFilterModels/dailyDataFiltersModel";
import { DailyDataStackedFiltersModel } from "app/models/scannergFilterModels/dailyDataStackedFiltersModel";
import { EnergyTimeButtonsFilter } from "app/models/scannergFilterModels/energyFilterTimeButtonsModel";
import { MonthlyDataFiltersModel } from "app/models/scannergFilterModels/monthlyDataFiltersModel";
import { MonthlyDataStackedFiltersModel } from "app/models/scannergFilterModels/monthlyDataStackedFiltersModel";
import { PzuEnergyFiltersModel } from "app/models/scannergFilterModels/pzuEnergyFiltersModel";
import { StackedPzuEnergyFiltersModel } from "app/models/scannergFilterModels/pzuEnergyStackedFiltersModel";
import { SourcesModel } from "app/models/sources/sourcesModel";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";
import { StaticDataDto } from "app/models/essential/StaticDataDto";
import { Co2PetrolDto } from "app/models/essential/Co2Petrol";
import { PzuEssentialChartDto } from "app/models/essential/PzuEssentialChart";
import { EssentialLocalExternDto } from "app/models/essential/EssentialLocalExternDto";

@Injectable({
    providedIn: "root",
})
export class EssentialService {
    private _apiUserUrl = ApiConstants.ESSENTIAL_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getEnergyPzuEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/energyPzu`);
    }

    getGasPzuEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/gasPzu`);
    }

    getGasNextMonthEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/gasNextMonth`);
    }

    getEnergyNextYearEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/energyNextYear`);
    }

    getEnergyNextMonthEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/energyNextMonth`);
    }

    getGasNextSeasonEssentialStatic(): Observable<StaticDataDto> {
        return this.apiService.get<StaticDataDto>(`${this._apiUserUrl}/gasNextSeason`);
    }

    getCo2PetrolChartData(): Observable<Co2PetrolDto> {
        return this.apiService.get<Co2PetrolDto>(`${this._apiUserUrl}/co2Petrol`);
    }

    getPzuChartData(): Observable<PzuEssentialChartDto> {
        return this.apiService.get<PzuEssentialChartDto>(`${this._apiUserUrl}/pzuEssentialChartData`);
    }

    getNextMonthChartData(): Observable<EssentialLocalExternDto> {
        return this.apiService.get<EssentialLocalExternDto>(`${this._apiUserUrl}/nextMonthChartData`);
    }

    getNextYearChartData(): Observable<EssentialLocalExternDto> {
        return this.apiService.get<EssentialLocalExternDto>(`${this._apiUserUrl}/nextYearChartData`);
    }
}