import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export interface ValidationResult {
  [key: string]: boolean;
}

export function forbiddenCharactersValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}

export function onlyChar(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value == '') return null;

    let re = new RegExp('^[a-zA-Z ]*$');
    if (re.test(control.value)) {
      return null;
    } else {
      return { onlyChar: true };
    }
  };
}

export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}

export function strong(control: FormControl): ValidationResult {
  let hasNumber = /\d/.test(control.value);
  let hasUpper = /[A-Z]/.test(control.value);
  let hasLower = /[a-z]/.test(control.value);
  // let hasSpecial = /[$@$!%*?&]/.test(control.value);
  const valid = hasNumber && hasUpper && hasLower;
  if (valid) {
    // return what´s not valid
    return { strong: true };
  }
  return { strong: false };
}