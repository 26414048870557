import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scannerg-scannerg-volumes-electric-energy',
  templateUrl: './scannerg-volumes-electric-energy.component.html',
  styleUrls: ['./scannerg-volumes-electric-energy.component.scss']
})
export class ScannergVolumesElectricEnergyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
