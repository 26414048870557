import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserForActivationDto } from 'app/models/user/userForActivationDto';
import { UserForTableDto } from 'app/models/user/userForTableDto';
import { DialogService } from 'app/services/dialog.service';
import { UserService } from 'app/services/user.service';
import { SelfUnsubscriberBase } from 'app/shared/components/base/SelfUnsubscriberBase';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-activate-user-modal-content-content',
    templateUrl: './activate-user-modal-content.component.html',
    styleUrls: ['./activate-user-modal-content.component.scss']
})
export class ActivateUserModalContent extends SelfUnsubscriberBase implements OnInit {

    @Output() changed = new EventEmitter();
    @Input() user: UserForTableDto;
    filterForm: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private dialogService: DialogService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.filterForm = new FormGroup({
            activeUntil: new FormControl(0, Validators.required),
        })
    }

    activateUser() {
        const userForActivationDto: UserForActivationDto = {
            email: this.user.email,
            activeUntilDateTime: this.formatDate(this.filterForm.get("activeUntil").value)
        }
        this.dialogService
            .showDialog({
                title: `Ești sigur că vrei să activezi acest cont până la data de ${this.formatDate(this.filterForm.get("activeUntil").value)}`,
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#18ce0f",
                confirmButtonText: "Confirmă",
            })
            .then((result) => {
                if (!result.value) return;
                this.userService.activateUser(userForActivationDto)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.activeModal.close('Close click');
                        this.changed.emit();
                    })
            });
    }

    deactivateUser() {
        const userForActivationDto: UserForActivationDto = {
            email: this.user.email,
            activeUntilDateTime: "2022-02-01"
        }
        this.dialogService
            .showDialog({
                title: `Ești sigur că vrei să dezactivezi acest cont`,
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#18ce0f",
                confirmButtonText: "Accept",
            })
            .then((result) => {
                if (!result.value) return;
                this.userService.deactivateUser(userForActivationDto)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.activeModal.close('Close click');
                        this.changed.emit();
                    })
            });
    }

    private formatDate(date: any) {
        if (date.month.toString().length < 2) {
            date.month = "0" + date.month;
        }
        if (date.day.toString().length < 2) {
            date.day = "0" + date.day;
        }
        return `${date.year}-${date.month}-${date.day}`;
    }
}