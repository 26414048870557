import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";
import { ContactDto } from "app/models/email/ContactDto";

@Injectable({
    providedIn: "root",
})
export class DataService {
    private _apiUserUrl = ApiConstants.DATA_ENDPOINT;

    constructor(private apiService: ApiService) { }

    updateData(): Observable<any> {
        return this.apiService.get<any>(`${this._apiUserUrl}`);
    }

}