export class ApiConstants {
  public static readonly USER_API_ENDPOINT = "api/accounts";
  public static readonly ELECTRIC_ENERGY_ENDPOINT = "api/electricEnergy";
  public static readonly NATURAL_GAS_ENDPOINT = "api/naturalGas";
  public static readonly FILTER_ENDPOINT = "api/filter";
  public static readonly EXTRA_PARAMS_ENDPOINT = "api/extraParams";
  public static readonly LEGAL_PRESS_ENDPOINT = "api/legalPress"
  public static readonly ESSENTIAL_ENDPOINT = "api/essential"
  public static readonly EMAIL_ENDPOINT = "api/email"
  public static readonly DATA_ENDPOINT = "api/data"

}
