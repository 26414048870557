import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss']
})

export class EventCardComponent implements OnInit {

    @Output() showMorePressed = new EventEmitter();

    constructor(private router: Router) {

    }
    ngOnInit() { }

    onClickBlogCard() {
        this.router.navigateByUrl('/blog-post');
    }
    moreInfoClicked() {
        this.showMorePressed.emit();
    }
}
