import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiConstants } from 'app/constants/api-constants';
import { AuthResponseDto } from 'app/models/user/authResponseDto';
import { CurrentUserRoleDto } from 'app/models/user/currentUserRoleDto';
import { ForgotPasswordDto } from 'app/models/user/forgotPasswordDto';
import { RegistrationResponseDto } from 'app/models/user/registrationResponseDto';
import { ResetPasswordDto } from 'app/models/user/resetPasswordDto';
import { UserForAuthenticationDto } from 'app/models/user/UserForAuthenticationDto';
import { UserForRegistrationDto } from 'app/models/user/userForRegistrationDto';
import { ApiService } from 'app/shared/services/api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _apiUserUrl = ApiConstants.USER_API_ENDPOINT;
  private _authChangeSub = new Subject<boolean>()
  public authChanged = this._authChangeSub.asObservable();

  constructor(private apiService: ApiService, private jwtHelper: JwtHelperService) { }

  public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
    this._authChangeSub.next(isAuthenticated);
  }

  public registerUser = (body: UserForRegistrationDto) => {
    return this.apiService.post<RegistrationResponseDto>(`${this._apiUserUrl}/registration`, body);
  }

  // private createCompleteRoute = (route: string, envAddress: string) => {
  //   return `${envAddress}/${route}`;
  // }

  public loginUser = (body: UserForAuthenticationDto) => {
    return this.apiService.post<AuthResponseDto>(`${this._apiUserUrl}/login`, body);
  }

  public logout = () => {
    localStorage.removeItem("token");
    this.sendAuthStateChangeNotification(false);
  }

  public isUserAuthenticated = (): boolean => {
    const token = localStorage.getItem("token");
    const response = token && !this.jwtHelper.isTokenExpired(token);
    return response;
  }

  public getCurrentRole = () => {
    return this.apiService.get<CurrentUserRoleDto>(`${this._apiUserUrl}/CurrentRole`);
  }

  public forgotPassword = (forgotPasswordDto: ForgotPasswordDto) => {
    return this.apiService.post<any>(`${this._apiUserUrl}/forgotPassword`, forgotPasswordDto);
  }

  public resetPassword = (resetPasswordDto: ResetPasswordDto) => {
    return this.apiService.post<any>(`${this._apiUserUrl}/resetPassword`, resetPasswordDto);
  }
}