import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
    selector: 'app-footer-scannerg',
    templateUrl: './footer-scannerg.component.html',
    styleUrls: ['./footer-scannerg.component.scss']
})
export class FooterScannergComponent implements OnInit {
    test: Date = new Date();
    permissionGranted: boolean = false;
    constructor(private authenticationService: AuthenticationService) { }

    ngOnInit() {
        if (this.authenticationService.isUserAuthenticated()) {
            this.permissionGranted = true;
        }
        else {
            this.permissionGranted = false;
        }
    }

    openGhid() {
        window.open("api/legalPress/GhidUtilizare.pdf", '_blank');
    }

    onLinkedInIcon() {
        window.open("https://www.linkedin.com/company/scannergi", '_blank');
    }
}
