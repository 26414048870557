import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";
import { ContactDto } from "app/models/email/ContactDto";

@Injectable({
    providedIn: "root",
})
export class EmailService {
    private _apiUserUrl = ApiConstants.EMAIL_ENDPOINT;

    constructor(private apiService: ApiService) { }

    sendContactMessage(contact: ContactDto): Observable<any> {
        return this.apiService.post<any>(`${this._apiUserUrl}/contact`, contact);
    }

}