import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-scannerg-advanced',
  templateUrl: './scannerg-advanced.component.html',
  styleUrls: ['./scannerg-advanced.component.scss']
})

export class ScannergAdvancedComponent implements OnInit {
  constructor(private titleService: Title
    ) {
  }
  ngOnInit(): void {
    this.titleService.setTitle("partenerg | Analizele tale")
  }
}
