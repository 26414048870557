import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserForAuthenticationDto } from 'app/models/user/UserForAuthenticationDto';
import { AuthenticationService } from 'app/services/authentication.service';
import { strong } from '../validators/signup.validators';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
    test: Date = new Date();
    focus;
    focus1;
    loading: boolean = false;
    public loginForm: FormGroup;
    public errorMessage: string = '';
    public showError: boolean;
    private _returnUrl: string;
    errorList: string[] = [];

    constructor(private _authService: AuthenticationService, private _router: Router, private _route: ActivatedRoute, private titleService: Title) {
    }

    ngOnInit(): void {
        this.titleService.setTitle("partenerg | Logare")
        this.loginForm = new FormGroup({
            username: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
            password: new FormControl("", [Validators.required, strong])
        })
        this._returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/essential';
    }

    public validateControl = (controlName: string) => {
        return this.loginForm.controls[controlName].invalid && this.loginForm.controls[controlName].touched
    }
    public hasError = (controlName: string, errorName: string) => {
        return this.loginForm.controls[controlName].hasError(errorName)
    }
    public loginUser = (loginFormValue) => {
        this.showError = false;
        const login = { ...loginFormValue };
        const userForAuth: UserForAuthenticationDto = {
            email: login.username,
            password: login.password
        }
        this.loading = true;
        this._authService.loginUser(userForAuth)
            .subscribe(res => {
                if(res.isAuthSuccessful)
                {
                    this.errorMessage = '';
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("name", res.name);
                    this._authService.sendAuthStateChangeNotification(res.isAuthSuccessful);
                    this._router.navigate([this._returnUrl]);
                }
                else{
                    this.errorMessage = res.errorMessage;
                }
                this.loading = false;

            },
                (error) => {
                    this.errorMessage = error.errorMessage;
                    this.showError = true;
                    this.loading = false
                })
    }
}
