import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { RoleTypesHierarchy } from 'app/constants/roleTypesHierarchy';
import { AuthenticationService } from 'app/services/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthenticationService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._authService.isUserAuthenticated()) {
      return new Observable<boolean>((permisionStatus) => {
        this._authService.getCurrentRole().subscribe(
          (data) => {
            const roleValue = RoleTypesHierarchy.RoleTypesHierarchy.filter(role => role.name === data.name)[0].value;
            if (roleValue >= route.data.minRole) {
              return permisionStatus.next(true);
            }
            else {
              this._router.navigate(['/**']);
              return permisionStatus.next(false);
            }
          },
          (_) => {
            this._router.navigate(['/signin'], { queryParams: { returnUrl: state.url } });
            return permisionStatus.next(false);
          }
        )
      });
    }
    else {
      this._router.navigate(['/signin'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
