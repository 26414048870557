import { Injectable } from "@angular/core";
import { ExtraParamsDto } from "app/models/ExtraParameters/extraParamsDto";
import { StackedExtraParamsDto } from "app/models/ExtraParameters/stackedExtraParamsDto";
import { MonthlyExtraParamsFilter } from "app/models/scannergFilterModels/monthlyExtraParamsFilter";
import { MonthlyStackedExtraParamsFilter } from "app/models/scannergFilterModels/monthlyStackedExtraParamsFilter";
import { StackedStartEndDateModel } from "app/models/scannergFilterModels/stackedStartEndDateModel";
import { StartEndDateModel } from "app/models/scannergFilterModels/startEndDateModel";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class ExtraParamsService {
    private _apiUserUrl = ApiConstants.EXTRA_PARAMS_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getExtraParams(filters: StartEndDateModel): Observable<ExtraParamsDto[]> {
        return this.apiService.post<ExtraParamsDto[]>(this._apiUserUrl, filters);
    }

    getMonthlyExtraParams(filters: MonthlyExtraParamsFilter): Observable<ExtraParamsDto[]> {
        return this.apiService.post<ExtraParamsDto[]>(`${this._apiUserUrl}/monthlyData`, filters);
    }

    getStackedExtraParams(filters: StackedStartEndDateModel): Observable<StackedExtraParamsDto[]> {
        return this.apiService.post<StackedExtraParamsDto[]>(`${this._apiUserUrl}/stacked`, filters);
    }

    getStackedMonthlyExtraParams(filters: MonthlyStackedExtraParamsFilter): Observable<StackedExtraParamsDto[]> {
        return this.apiService.post<StackedExtraParamsDto[]>(`${this._apiUserUrl}/stacked/monthly`, filters);
    }

    getAllExtraParamsDaily(filters: StartEndDateModel): Observable<ExtraParamsDto[]> {
        return this.apiService.post<ExtraParamsDto[]>(`${this._apiUserUrl}/extraParams`, filters);
    }
}