import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PzuNaturalGasDto } from 'app/models/naturalGas/pzuNaturalGasDto';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-pzu-natural-gas-grid',
  templateUrl: './pzu-natural-gas-grid.component.html',
  styleUrls: ['./pzu-natural-gas-grid.component.scss']
})
export class PzuNaturalGasGridComponent extends GridComponentBase<PzuNaturalGasDto> implements OnInit {

  @Input() isGas;
  @Input() inputData;

  constructor() {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T").join(" "),
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('DD-MM-YYYY')
        }
      },
      {
        field: "indexValue",
        headerName: "Preț de închidere a pieței",
        width: 200,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },
      {
        field: "volume",
        headerName: "Volum tranzacționat",
        width: 170,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },

    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData: PzuNaturalGasDto[] = changes.inputData.currentValue
    if (!changedData) return;
    this.data = changedData;
  }

  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
