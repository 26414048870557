import { Injectable } from "@angular/core";
import { ElectricEnergyDto } from "app/models/electricEnergy/electricEnergyDto";
import { electricEnergyProductDto } from "app/models/electricEnergy/electricEnergyPricesProductDto";
import { ElectricEnergyMonthlyQuotes } from "app/models/electricEnergy/monthlyEnergyQuotes";
import { NaturalGasMonthlyQuotes } from "app/models/electricEnergy/monthlyGasQuotes";
import { StackedMonthlyNaturalGasDto } from "app/models/electricEnergy/stackedMonthlyNaturalGasDto";
import { StackedNaturalGasDto } from "app/models/electricEnergy/stackedNaturalGasDto";
import { MonthlyNaturalGasEnergyQuotesDto } from "app/models/naturalGas/monthlyNaturalGasQuotesDto";
import { ProducersModel } from "app/models/producers/producersModel";
import { ProvidersModel } from "app/models/providers/providersModel";
import { DailyDataFiltersModel } from "app/models/scannergFilterModels/dailyDataFiltersModel";
import { DailyDataStackedFiltersModel } from "app/models/scannergFilterModels/dailyDataStackedFiltersModel";
import { GasTimeButtonsFilter } from "app/models/scannergFilterModels/gasFilterTimeButtonsModel";
import { MonthlyDataFiltersModel } from "app/models/scannergFilterModels/monthlyDataFiltersModel";
import { MonthlyDataStackedFiltersModel } from "app/models/scannergFilterModels/monthlyDataStackedFiltersModel";
import { PzuNaturalGasFiltersModel } from "app/models/scannergFilterModels/pzuNaturalGasFiltersModel";
import { Observable } from "rxjs";
import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class NaturalGasService {
    private _apiUserUrl = ApiConstants.NATURAL_GAS_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getFilteredNaturalGas(filters: DailyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(this._apiUserUrl, filters);
    }

    getMonthlyFilteredNaturalGas(filters: MonthlyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/monthlyData`, filters);
    }

    getFilteredMonthlyPzuNaturalGas(filters: MonthlyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/monthlyPzu`, filters);
    }

    getFilteredDailyPzuNaturalGas(filters: DailyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/dailyPzu`, filters);
    }

    getFilteredStackedNaturalGas(filters: DailyDataStackedFiltersModel): Observable<StackedNaturalGasDto[]> {
        return this.apiService.post<StackedNaturalGasDto[]>(`${this._apiUserUrl}/stackedGas`, filters);
    }

    getFilteredStackedNaturalGasMonthly(filters: MonthlyDataStackedFiltersModel): Observable<StackedMonthlyNaturalGasDto[]> {
        return this.apiService.post<StackedMonthlyNaturalGasDto[]>(`${this._apiUserUrl}/stackedGas/monthlyData`, filters);
    }

    getFilteredStackedNaturalGasPzuMonthly(filters: MonthlyDataStackedFiltersModel): Observable<StackedMonthlyNaturalGasDto[]> {
        return this.apiService.post<StackedMonthlyNaturalGasDto[]>(`${this._apiUserUrl}/stackedGas/pzuMonthlyData`, filters);
    }

    getFilteredStackedDailyPzuNaturalGas(filters: DailyDataStackedFiltersModel): Observable<StackedNaturalGasDto[]> {
        return this.apiService.post<StackedNaturalGasDto[]>(`${this._apiUserUrl}/stackedGas/dailyPzu`, filters);
    }

    getGasPricesProducts(filters: DailyDataFiltersModel): Observable<electricEnergyProductDto[]> {
        return this.apiService.post<electricEnergyProductDto[]>(`${this._apiUserUrl}/priceGas`, filters);
    }

    getGasDataChart(filters: GasTimeButtonsFilter): Observable<any> {
        return this.apiService.post<any>(`${this._apiUserUrl}/priceGas/nextShipmentChart`, filters);
    }

    getGasMonthlyQuotes(filters: GasTimeButtonsFilter): Observable<NaturalGasMonthlyQuotes[]> {
        return this.apiService.post<NaturalGasMonthlyQuotes[]>(`${this._apiUserUrl}/monthlyQuotes`, filters);
    }

    getGasProducersMonthly(filters: GasTimeButtonsFilter): Observable<ProducersModel[]> {
        return this.apiService.post<ProducersModel[]>(`${this._apiUserUrl}/producers`, filters);
    }

    getGasProviders(filters: GasTimeButtonsFilter): Observable<ProvidersModel[]> {
        return this.apiService.post<ProvidersModel[]>(`${this._apiUserUrl}/providers`, filters);
    }

    getGasPZUDataChart(filters: PzuNaturalGasFiltersModel): Observable<any> {
        return this.apiService.post<any>(`${this._apiUserUrl}/priceGas/PZUchart`, filters);
    }

    getGasMonthlyQuotesDataChart(filters: PzuNaturalGasFiltersModel): Observable<any> {
        return this.apiService.post<MonthlyNaturalGasEnergyQuotesDto[]>(`${this._apiUserUrl}/priceGas/MonthlyQuotes`, filters);
    }
}