import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FilterConstants } from 'app/constants/filterConstants';
import { ExtraParamsDto } from 'app/models/ExtraParameters/extraParamsDto';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-extra-params-grid',
  templateUrl: './extra-params-grid.component.html',
  styleUrls: ['./extra-params-grid.component.scss']
})
export class ExtraParamsGridComponent extends GridComponentBase<ExtraParamsDto> implements OnInit {

  @Input() inputData;
  @Input() isStacked = false;

  constructor() {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let calculatedColumns = [];
    var changedData = changes.inputData.currentValue
    if (!changedData) return;
    if (this.isStacked) {
      let tempData = [];
      changedData["ExtraParams"].forEach(entry => {
        tempData = tempData.concat(entry.extraParameters)
      })
      this.data = tempData;
    }
    else {
      this.data = changedData["ExtraParams"];
    }
    if (changedData["ExtraParamsList"].length > 0) {
      calculatedColumns.push(
        {
          valueGetter: x => x.data.date.split("T").join(" "),
          headerName: "Data",
          width: 150,
          suppressSizeToFit: false,
          cellRenderer: (data) => {
            return moment(data.value).format('DD-MM-YYYY')
          }
        }
      )
      FilterConstants.extraParamsList.forEach(param => {
        if (changedData["ExtraParamsList"].includes(param.id)) {
          let gridColumn = {
            valueGetter: x => ((FilterConstants.marketList.filter(profile => profile.id == x.data.market)[0].name)),
            headerName: param.name,
            width: 170,
            suppressSizeToFit: false,
          };
          switch (param.id) {
            case 1:
              gridColumn.valueGetter = x => (x.data.certificateEmisiiCo2.toFixed(2));
              break;
            case 2:
              gridColumn.valueGetter = x => (x.data.petrol.toFixed(2));
              break;
            case 3:
              gridColumn.valueGetter = x => ((x.data.umplereLacuriAcumulare * 100).toFixed(2))
              break;
            case 4:
              gridColumn.valueGetter = x => ((x.data.inmagazinareGazeNaturale * 100).toFixed(2))
              break;
            default:
          }
          calculatedColumns.push(gridColumn);
        }
      })
      this.gridOptions.columnDefs = (calculatedColumns)
    }
  }

  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
