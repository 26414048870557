import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-scannerg-prices-electric-energy",
  templateUrl: "./scannerg-prices-electric-energy.component.html",
  styleUrls: ["./scannerg-prices-electric-energy.component.scss"],
})
export class ScannergPricesElectricEnergyComponent implements OnInit {
  
  constructor() {}

  ngOnInit(): void {
  }
}
