import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { ElectricEnergyDto } from 'app/models/electricEnergy/electricEnergyDto';
import { FilterConstants } from 'app/constants/filterConstants';
import { ExtraParamsDto } from 'app/models/ExtraParameters/extraParamsDto';
import { timeout } from 'rxjs/operators';
import { Colours } from 'app/constants/colors';
import * as moment from 'moment';


@Component({
  selector: 'app-energy-chart',
  templateUrl: './energy-chart.component.html',
  styleUrls: ['./energy-chart.component.scss']
})
export class EnergyChartComponent implements OnInit {

  @Input() isGas;
  @Input() extraParamsList;
  @Input() isBothCommodity;
  @Input() inputData;
  @Input() showMoreProfiles;
  chartOption: EChartsOption;
  myChart: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (!changes.currentValue) {
    //   return;
    // }

    let changedData = changes.inputData.currentValue
    let showProcent: boolean = false;
    let calculatedSeries = [];
    let dateDict = {};

    let categories: string[] = [];


    if (this.isBothCommodity) {
      let productDict = {};
      changedData["Gaz"].forEach(entry => {
        if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
          dateDict[entry.date.split("T")[0]].push(entry)
        }
        else {
          dateDict[entry.date.split("T")[0]] = [entry]
        }
        if (Array.isArray(productDict[entry.product])) {
          productDict[entry.product].push(entry);
        }
        else {
          productDict[entry.product] = [entry]
        }
      })
      let copyDict = productDict;
      Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
        productDict[key] = {};
        value.forEach(element => {
          if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
          }
          else {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
          }
        });

      })
      Object.entries(productDict).forEach(([key, value]) => {
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
          categories.push(`${key} ${marketKey}`)
        });
      });
      Object.entries(productDict).forEach(([key, value]) => {
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
          calculatedSeries.push(
            {
              name: `${key} ${marketKey}`,
              type: 'line',
              axisLabel: {
                fontFamily: 'Arial',
                color: '#595959'
              },
              data: marketValue.map(x => [moment(x.date).format('DD-MM-YYYY'), x.indexValue])
            },
          )
        });

      });
      productDict = {};
      changedData["Energie"].forEach(entry => {
        if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
          dateDict[entry.date.split("T")[0]].push(entry)
        }
        else {
          dateDict[entry.date.split("T")[0]] = [entry]
        }
        if (Array.isArray(productDict[entry.product])) {
          productDict[entry.product].push(entry);
        }
        else {
          productDict[entry.product] = [entry]
        }
      })
      copyDict = productDict;
      Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
        productDict[key] = {};
        value.forEach(element => {
          if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
          }
          else {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
          }
        });

      })
      Object.entries(productDict).forEach(([key, value]) => {
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
          categories.push(`${key} ${marketKey}`)
        });
      });
      Object.entries(productDict).forEach(([key, value]) => {
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {

          calculatedSeries.push(
            {
              name: `${key} ${marketKey}`,
              type: 'line',
              axisLabel: {
                fontFamily: 'Arial',
                color: '#595959'
              },
              data: marketValue.map(x => [moment(x.date).format('DD-MM-YYYY'), x.chartValue])
            },
          )
        });

      });
    }
    else {
      let productDict = {};
      let data;
      if (this.isGas) {
        data = changedData["Gaz"]
      }
      else {
        data = changedData["Energie"]
      }
      data.forEach(entry => {
        if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
          dateDict[entry.date.split("T")[0]].push(entry)
        }
        else {
          dateDict[entry.date.split("T")[0]] = [entry]
        }
        if (Array.isArray(productDict[entry.product])) {
          productDict[entry.product].push(entry);
        }
        else {
          productDict[entry.product] = [entry]
        }
      })
      const copyDict = productDict;
      Object.entries(copyDict).forEach(([key, value]: [string, ElectricEnergyDto[]]) => {
        productDict[key] = {};
        value.forEach(element => {
          if (Array.isArray(productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name])) {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name].push(element);
          }
          else {
            productDict[key][FilterConstants.marketList.filter(x => x.id == element.market)[0].name] = [element]
          }
        });

      })
      Object.entries(productDict).forEach(([key, value]) => {
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
          categories.push(`${key} ${marketKey}`)
        });
      });

      Object.entries(productDict).forEach(([key, value]) => {
        let category: string;
        Object.entries(value).forEach(([marketKey, marketValue]: [string, ElectricEnergyDto[]]) => {
          category = `${key} ${marketKey}`;
          calculatedSeries.push(
            {
              name: `${key} ${marketKey}`,
              type: 'line',
              axisLabel: {
                fontFamily: 'Arial',
                color: '#595959'
              },
              data: this.isGas ? marketValue.map(x => [moment(x.date).format('DD-MM-YYYY'), x.indexValue]) : marketValue.map(x => [moment(x.date).format('DD-MM-YYYY'), x.chartValue])
            },
          )
        });

      });
    }
    if (changedData["ExtraParamsList"].length > 0) {
      FilterConstants.extraParamsList.forEach(param => {
        if (changedData["ExtraParamsList"].includes(param.id)) {
          categories.push(param.name);
          let seriesToAdd = {
            name: param.name,
            axisLabel: {
              fontFamily: 'Arial',
              color: '#595959'
            },
            type: 'line',
            data: [],
            yAxisIndex: 0,
          };
          switch (param.id) {
            case 1:
              seriesToAdd.data = changedData["ExtraParams"].map((x: ExtraParamsDto) => [moment(x.date).format('DD-MM-YYYY'), x.certificateEmisiiCo2])
              break;
            case 2:
              seriesToAdd.data = changedData["ExtraParams"].map((x: ExtraParamsDto) => [moment(x.date).format('DD-MM-YYYY'), x.petrol])
              break;
            case 3:
              seriesToAdd.data = changedData["ExtraParams"].map((x: ExtraParamsDto) => [moment(x.date).format('DD-MM-YYYY'), x.umplereLacuriAcumulare * 100])
              seriesToAdd.yAxisIndex = 1;
              showProcent = true;
              break;
            case 4:
              seriesToAdd.data = changedData["ExtraParams"].map((x: ExtraParamsDto) => [moment(x.date).format('DD-MM-YYYY'), x.inmagazinareGazeNaturale * 100])
              seriesToAdd.yAxisIndex = 1;
              showProcent = true;
              break;
            default:
          }
          calculatedSeries.push(seriesToAdd);
        }
      })
    }
    let yaxys: echarts.YAXisComponentOption[] = [
      {
        type: 'value',
        name: "Preț",
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959',
        },
      }]
    if (showProcent) {
      yaxys.push(
        {
          type: 'value',
          name: 'Procent',
          min: 0,
          max: 100,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959',
            formatter: '{value} %'
          },
          position: 'right',
          axisLine: {
            show: true,
          },
        },
      )
    }
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        },

      },
      legend: {
        type: 'scroll',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        bottom: '1%',
        data: categories,
      },
      toolbox: {
        feature: {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: 'Grafic'
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "20%",
        containLabel: true
      },
      dataZoom: [
        {
          left: "7%",
          right: "10%",
          bottom: 40,
          textStyle: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          backgroundColor: '#B5F1FF',
          dataBackground: {
            lineStyle: {
              color: '#003A47'
            },
            // areaStyle: {
            //   color: '#00758F'
            // }
          },
          borderColor: '#003A47',
          fillerColor: '#47C6A3'
        },
        {
          type: "inside"
        }
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          nameTextStyle: {
            fontFamily: "Arial"
          },
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(entry => moment(entry).format('DD-MM-YYYY'))
        }
      ],
      yAxis: yaxys,
      series: calculatedSeries,
      color: Colours.ColorPallete
    };


  }
}
