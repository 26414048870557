import { Component, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { EnergyTimeButtonsFilter } from 'app/models/scannergFilterModels/energyFilterTimeButtonsModel';
import { takeUntil } from 'rxjs/operators';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { NaturalGasMonthlyQuotes } from 'app/models/electricEnergy/monthlyGasQuotes';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { Colours } from 'app/constants/colors';
import * as moment from 'moment';


@Component({
  selector: 'scannerg-volumes-natural-gas-production-consume',
  templateUrl: './scannerg-volumes-natural-gas-production-consume.component.html',
  styleUrls: ['./scannerg-volumes-natural-gas-production-consume.component.scss']
})
export class ScannergVolumesNaturalGasProductionConsumeComponent extends GridComponentBase<NaturalGasMonthlyQuotes> implements OnInit {

  chartOption: EChartsOption;
  myChart: any;
  chartData: NaturalGasMonthlyQuotes[];
  period: number = 12;
  filters: EnergyTimeButtonsFilter;

  constructor(private gasService: NaturalGasService) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T").join(" "),
        headerName: "Data",
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: (data) => {
          return moment(data.value).format('MM-YYYY')
        }
      },
      {
        valueGetter: x => x.data.volumProductieGenerata.toFixed(2),
        headerName: "Volum producție generată",
        width: 300,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => x.data.volumConsum.toFixed(2),
        headerName: "Volum consum",
        width: 300,
        suppressSizeToFit: false,
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
    this.loadOneYearData();
  }

  private initChart() {
    let series = [];
    let dateDict = {};
    if (this.period == 1) {
      series = [
        {
          name: 'Producție-consum',
          type: 'pie',
          radius: '70%',
          data: [
            { value: this.chartData[0].volumProductieGenerata, name: 'Volum producție generată' },
            { value: this.chartData[0].volumConsum, name: 'Volum consum' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }
    else {
      this.chartData.forEach(entry => {
        if (Array.isArray(dateDict[entry.date.split("T")[0].substring(0, 7)])) {
          dateDict[entry.date.split("T")[0].substring(0, 7)].push(entry)
        }
        else {
          dateDict[entry.date.split("T")[0].substring(0, 7)] = [entry]
        }
      })
      series.push(
        {
          name: `Volum producție generată`,
          type: 'bar',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: this.chartData.map(x => [moment(x.date).format('MM-YYYY'), x.volumProductieGenerata])
        },
      )
      series.push(
        {
          name: `Volum consum`,
          type: 'bar',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: this.chartData.map(x => [moment(x.date).format('MM-YYYY'), x.volumConsum])
        },
      )
    }
    this.chartOption = {
      title: {
        text: "Producție-consum gaze naturale",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      },
      tooltip: this.period == 1 ? {
        trigger: "item",
      } : {
        trigger: "axis",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: [
          "Volum consum",
          "Volum producție generată"
        ],
      },
      toolbox: {
        top: 0,
        feature: this.period == 1 ? {} : {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: "Volum producție-consum gaze naturale"
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: this.period == 1 ? [] : [
        {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(x => moment(x).format('MM-YYYY'))
        },
      ],
      yAxis: this.period == 1 ? [] : [
        {
          type: 'value',
          position: 'right',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          axisLine: {
            show: true,
          },
        },
      ],
      series: series,
      color: Colours.ColorPallete
    };
  }

  public loadOneMonthData() {
    this.filters = {
      product: "",
      fromDate: -10,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.period = 1;
        this.chartData = entries;
        this.initChart();
      });
  }

  public loadThreeMonthsData() {
    this.filters = {
      product: "",
      fromDate: -3,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.period = 3;
        this.chartData = entries;
        this.initChart();
      });
  }

  public loadOneYearData() {
    this.filters = {
      product: "",
      fromDate: -12,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.chartData = entries;
        this.period = 12;
        this.data = entries;
        this.initChart();
      });
  }
  public loadTwoYearsData() {
    this.filters = {
      product: "",
      fromDate: -24,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasMonthlyQuotes(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.chartData = entries;
        this.period = 24;
        this.initChart();
      });
  }

  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
