import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactDto } from 'app/models/email/ContactDto';
import { EmailService } from 'app/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  public contactForm: FormGroup;
  showSuccess: boolean = false;
  constructor(private emailService: EmailService) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      message: new FormControl('', [Validators.required])
    });
  }

  sendMessage() {
    // console.log(this.contactForm.get("name").value);
    var contactDto: ContactDto = {
      name: this.contactForm.get("name").value,
      email: this.contactForm.get("email").value,
      message: this.contactForm.get("message").value,
    }
    this.emailService.sendContactMessage(contactDto).subscribe(
      message => {
        console.log("success");
        this.contactForm.reset();
        this.showSuccess = true;
      }
    )
  }
}
