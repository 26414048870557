import { Component, OnInit } from '@angular/core';
import { FilterConstants } from 'app/constants/filterConstants';
import { EnumModel } from 'app/models/scannergFilterModels/enumModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { DailyDataFiltersModel } from 'app/models/scannergFilterModels/dailyDataFiltersModel';
import { takeUntil } from 'rxjs/operators';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { FilterService } from 'app/services/filter.service';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { PzuEnergyFiltersModel } from 'app/models/scannergFilterModels/pzuEnergyFiltersModel';
import { dateValidator } from 'app/validators/datesValidator';

@Component({
  selector: 'app-scannerg-advanced-pzu-electric-unique-period',
  templateUrl: './scannerg-advanced-pzu-electric-unique-period.component.html',
  styleUrls: ['./scannerg-advanced-pzu-electric-unique-period.component.scss']
})

export class ScannergAdvancedPzuElectricUniquePeriodComponent extends LoadableComponentBase implements OnInit {

  data;
  focus: any;
  focus1: any;

  showData: boolean = false;
  enableSubmit: boolean = false;
  loading: boolean = false;

  //filter constants
  intervalList: EnumModel[] = FilterConstants.pzuIntervalList;


  //form
  filterForm: FormGroup;
  filters: PzuEnergyFiltersModel;

  constructor(
    private energyService: ElectricEnergyService,
  ) {
    super();

  }
  marketDropdownList = [];
  selectedItems = [];
  marketDropdownSettings: IDropdownSettings = {};
  productDropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.filterForm = new FormGroup({
      deliveryStart: new FormControl(0),
      deliveryEnd: new FormControl(0),
      interval: new FormControl(-1),

    }, { validators: [dateValidator] });
    this.onChanges();
  }

  onChanges(): void {
    this.filterForm.valueChanges.subscribe(val => {
      if (val.deliveryStart && val.deliveryEnd && val.interval != -1) {
        this.enableSubmit = true;
      }
      else {
        this.enableSubmit = false;
      }
      console.log(val.interval);

    });
  }

  private _loadData() {
    this.loading = true;
    this.energyService.getFilteredPzuElectricEnergy(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.showData = true;
        this.loading = false;
      });
  }

  applyFilter() {
    if (this.enableSubmit) {
      this.filters = {
        interval: this.filterForm.get("interval").value,
        toDate: this.formatDate(this.filterForm.get("deliveryEnd").value),
        fromDate: this.formatDate(this.filterForm.get("deliveryStart").value),
      }
      this._loadData();
      this.filterForm.disable();
    }
  }

  resetFilter() {
    this.data = undefined;
    this.filterForm.patchValue({
      "deliveryEnd": null,
      "deliveryStart": null,
      "interval": 0
    })
    this.showData = false;
    this.filterForm.enable();
  }

  private formatDate(date: any) {
    if (date.month.toString().length < 2) {
      date.month = "0" + date.month;
    }
    if (date.day.toString().length < 2) {
      date.day = "0" + date.day;
    }
    return `${date.year}-${date.month}-${date.day}`;
  }
}
