import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test: Date = new Date();

    constructor() { }

    ngOnInit() { }

    onLinkedInIcon() {
        window.open("https://www.linkedin.com/company/partnergi", '_blank');
    }
}
