import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scannerg-prices-natural-gas',
  templateUrl: './scannerg-prices-natural-gas.component.html',
  styleUrls: ['./scannerg-prices-natural-gas.component.scss']
})
export class ScannergPricesNaturalGasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
