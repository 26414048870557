import { Component, OnInit } from '@angular/core';
import { PzuNaturalGasFiltersModel } from 'app/models/scannergFilterModels/pzuNaturalGasFiltersModel';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-prices-natural-gas-echilibrare',
  templateUrl: './scannerg-prices-natural-gas-echilibrare.component.html',
  styleUrls: ['./scannerg-prices-natural-gas-echilibrare.component.scss']
})
export class ScannergPricesNaturalGasEchilibrareComponent extends LoadableComponentBase implements OnInit {

  data;
  filters: PzuNaturalGasFiltersModel;
  loading: boolean = false;
  
  constructor(private gasService: NaturalGasService) { 
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.gasService
      .getGasMonthlyQuotesDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.loading = false;
      });
  }
}
