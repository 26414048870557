import { Component, OnInit } from '@angular/core';
import { FilterConstants } from 'app/constants/filterConstants';
import { EnumModel } from 'app/models/scannergFilterModels/enumModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { takeUntil } from 'rxjs/operators';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { FilterService } from 'app/services/filter.service';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { forkJoin } from 'rxjs';
import { ExtraParamsService } from 'app/services/extra-params.service';
import { dateValidator } from 'app/validators/datesValidator';
import { MonthlyDataFiltersModel } from 'app/models/scannergFilterModels/monthlyDataFiltersModel';
import { MonthYearModel } from 'app/models/scannergFilterModels/monthYearModel';
import { MonthlyExtraParamsFilter } from 'app/models/scannergFilterModels/monthlyExtraParamsFilter';

@Component({
  selector: 'app-scannerg-advanced-monthly-data-unique-period',
  templateUrl: './scannerg-advanced-monthly-data-unique-period.component.html',
  styleUrls: ['./scannerg-advanced-monthly-data-unique-period.component.scss']
})

export class ScannergAdvancedMonthlyDataUniquePeriodComponent extends LoadableComponentBase implements OnInit {

  data = { "Gaz": [], "Energie": [], "ExtraParams": [], "ExtraParamsList": [] };
  energyData = [];
  gasData = [];
  focus: any;
  focus1: any;

  monthList = [
    { value: 1, name: "Ianuarie" },
    { value: 2, name: "Februarie" },
    { value: 3, name: "Martie" },
    { value: 4, name: "Aprilie" },
    { value: 5, name: "Mai" },
    { value: 6, name: "Iunie" },
    { value: 7, name: "Iulie" },
    { value: 8, name: "August" },
    { value: 9, name: "Septembrie" },
    { value: 10, name: "Octombrie" },
    { value: 11, name: "Noiembrie" },
    { value: 12, name: "Decembrie" },
  ]

  years = [
    2020,
    2021,
    2022,
    // 2023,
    // 2024,
    // 2025
  ]

  //filter constants
  showElectricEnergy: boolean = false;
  showNaturalGas: boolean = false;
  isGas: boolean = false;
  isPremiumAccount: boolean = false;
  commodityList: EnumModel[] = FilterConstants.commodityList;
  deliveryTypeList: EnumModel[] = FilterConstants.deliveryTypeList;
  deliveryProfileList: EnumModel[] = FilterConstants.deliveryProfileList;
  platformList: EnumModel[] = FilterConstants.platformList;
  marketList: EnumModel[] = FilterConstants.marketList;
  extraParamsList: EnumModel[] = FilterConstants.extraParamsList;


  //form
  isFormDisabled: boolean = false;
  extraParamsValues: number[] = [];
  energyProductList: string[] = [];
  gasProductList: string[];
  filterForm: FormGroup;
  filters: MonthlyDataFiltersModel;
  gasFilters: MonthlyDataFiltersModel;
  marketListFilter: number[] = [];
  showEnergyProducts: boolean = false;
  showGasProducts: boolean = false;
  showDeliveryProfile: boolean = false;
  showPlatform: boolean = false;
  showMarket: boolean = false;
  showExtraParams: boolean = false;
  enableButton: boolean = false;
  isBothCommodity: boolean = false;
  showData: boolean = false;

  marketDropdownList = [];
  selectedItems = [];
  marketDropdownSettings: IDropdownSettings = {};
  productDropdownSettings: IDropdownSettings = {};

  constructor(
    private energyService: ElectricEnergyService,
    private gasService: NaturalGasService,
    private extraParamsService: ExtraParamsService,
    private filterService: FilterService
  ) {
    super();
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      commodity: new FormControl(-1, Validators.required),
      deliveryType: new FormControl(-1),
      deliveryProfile: new FormControl([]),
      platform: new FormControl(-1),
      market: new FormControl([]),
      extraParams: new FormControl([]),
      energyProduct: new FormControl([]),
      gasProduct: new FormControl([]),
      startPeriodYear: new FormControl(-1),
      startPeriodMonth: new FormControl(-1),
      endPeriodYear: new FormControl(-1),
      endPeriodMonth: new FormControl(-1),
    }, { validators: [] })

    this.onChanges();
    this.marketDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "Nu există date pentru perioada selectată"
    }
    this.productDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "Nu există date pentru perioada selectată"
    }
  }

  private _loadData() {
    if (this.filterForm.get("deliveryType").value == 1) {
      if (this.isBothCommodity) {
        if (this.extraParamsValues.length > 0) {
          const startDateFilter: MonthlyExtraParamsFilter = {
            startDate: this.filters.fromDate,
            endDate: this.filters.toDate
          }
          forkJoin([
            this.extraParamsService.getMonthlyExtraParams(startDateFilter),
            this.energyService.getMonthlyFilteredElectricEnergy(this.filters),
            this.gasService.getMonthlyFilteredNaturalGas(this.gasFilters)
          ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              ([extraParams, energy, gas]) => {
                this.data["ExtraParams"] = extraParams;
                this.data.ExtraParamsList = this.extraParamsValues;
                this.data["Gaz"] = gas;
                this.data["Energie"] = energy;
                this.energyData = energy;
                this.gasData = gas;
                this.showData = true;
                this.showElectricEnergy = true;
                this.showNaturalGas = true;
              },
              (_) => {

              });
        }
        else {
          forkJoin([
            this.energyService.getMonthlyFilteredElectricEnergy(this.filters),
            this.gasService.getMonthlyFilteredNaturalGas(this.gasFilters)
          ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([energy, gas]) => {
              this.data["Gaz"] = gas;
              this.data["Energie"] = energy;
              this.energyData = energy;
              this.gasData = gas;
              this.showData = true;
              this.showElectricEnergy = true;
              this.showNaturalGas = true;
            });
        }
      } else
        if (this.filters.commodity == 1) {
          if (this.extraParamsValues.length > 0) {
            const startDateFilter: MonthlyExtraParamsFilter = {
              startDate: this.filters.fromDate,
              endDate: this.filters.toDate
            }
            forkJoin([
              this.extraParamsService.getMonthlyExtraParams(startDateFilter),
              this.energyService.getMonthlyFilteredElectricEnergy(this.filters)
            ])
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(([extraParams, entries]) => {
                this.data["ExtraParams"] = extraParams;
                this.data.ExtraParamsList = this.extraParamsValues;
                this.data["Energie"] = entries;
                this.energyData = entries;
                this.isGas = false;
                this.showData = true;
                this.showElectricEnergy = true;
                this.showNaturalGas = false;
              });
          }
          else {
            this.energyService.getMonthlyFilteredElectricEnergy(this.filters)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((entries) => {
                this.data["Energie"] = entries;
                this.energyData = entries;
                this.isGas = false;
                this.showData = true;
                this.showElectricEnergy = true;
                this.showNaturalGas = false;
              },
                (error) => {
                  console.log(error);

                });
          }
        }
        else
          if (this.filters.commodity == 2) {
            if (this.extraParamsValues.length > 0) {
              const startDateFilter: MonthlyExtraParamsFilter = {
                startDate: this.filters.fromDate,
                endDate: this.filters.toDate
              }
              forkJoin([
                this.extraParamsService.getMonthlyExtraParams(startDateFilter),
                this.gasService.getMonthlyFilteredNaturalGas(this.filters)
              ])
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(([extraParams, entries]) => {
                  this.data["ExtraParams"] = extraParams;
                  this.data.ExtraParamsList = this.extraParamsValues;
                  this.data["Gaz"] = entries;
                  this.gasData = entries;
                  this.isGas = true;
                  this.showData = true;
                  this.showElectricEnergy = false;
                  this.showNaturalGas = true;
                });
            }
            else {
              this.gasService.getMonthlyFilteredNaturalGas(this.filters)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((entries) => {
                  this.data["Gaz"] = entries;
                  this.gasData = entries;
                  this.isGas = true;
                  this.showData = true;
                  this.showElectricEnergy = false;
                  this.showNaturalGas = true;
                });
            }
          }
    }
    else
      if (this.isBothCommodity) {
        forkJoin([
          this.energyService.getFilteredMonthlyPzuElectricEnergy(this.filters),
          this.gasService.getFilteredMonthlyPzuNaturalGas(this.gasFilters)
        ])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(([energy, gas]) => {
            this.data["Gaz"] = gas;
            this.data["Energie"] = energy;
            this.energyData = energy;
            this.gasData = gas;
            this.showData = true;
            this.showElectricEnergy = true;
            this.showNaturalGas = true;
          });
      } else
        if (this.filters.commodity == 1) {
          this.energyService.getFilteredMonthlyPzuElectricEnergy(this.filters)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((entries) => {
              this.data["Energie"] = entries;
              this.energyData = entries;
              this.isGas = false;
              this.showData = true;
              this.showElectricEnergy = true;
              this.showNaturalGas = false;
            },
              (error) => {
                console.log(error);

              });
        } else {
          if (this.filters.commodity == 2) {
            this.gasService.getFilteredMonthlyPzuNaturalGas(this.filters)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((entries) => {
                this.data["Gaz"] = entries;
                this.gasData = entries;
                this.isGas = true;
                this.showData = true;
                this.showElectricEnergy = false;
                this.showNaturalGas = true;
              });
          }
        }
  }


  onChanges(): void {
    this.filterForm.valueChanges.subscribe(val => {

      console.log(val.startPeriodMonth);
      console.log(val.startPeriodYear);

      if (val.startPeriodMonth != -1 && val.endPeriodMonth != -1 && val.endPeriodYear != -1 && val.startPeriodYear != -1) {
        //daca e energie electrica selectata
        if (!this.filterForm.errors?.datesError) {
          if (val.commodity == FilterConstants.commodityList[0].id) {
            this.filters = {
              commodity: this.filterForm.get("commodity").value,
              market: this.filterForm.get("market").value.map(x => x.id),
              fromDate: this.createMonthYearModel(this.filterForm.get("startPeriodMonth").value, this.filterForm.get("startPeriodYear").value),
              toDate: this.createMonthYearModel(this.filterForm.get("endPeriodMonth").value, this.filterForm.get("endPeriodYear").value),
              platform: this.filterForm.get("platform").value,
              deliveryProfile: this.filterForm.get("deliveryProfile").value.map(x => x.id),
              product: this.filterForm.get("energyProduct").value
            }
            if (val.deliveryType == FilterConstants.deliveryTypeList[0].id) {
              this.getEnergyProductList();
              if (val.energyProduct.length != 0) {
                if (!this.showDeliveryProfile) {
                  this.getDeliveryProfiles();
                }
                if (val.deliveryProfile.length != 0) {
                  if (!this.showPlatform) {
                    this.getPlatforms();
                  }
                  if (val.platform >= 0) {
                    this.getMarkets(this.filters);
                    if (val.market.length != 0) {
                      this.showExtraParams = true;
                      this.extraParamsList = FilterConstants.extraParamsList.filter(x => x.id % 2 == 1)
                    }
                    else {
                      this.showExtraParams = false;
                    }
                    if (this.showExtraParams) {
                      this.enableButton = true;
                    }
                    else {
                      this.enableButton = false;
                    }
                  }
                  else {
                    if (this.showPlatform) {
                      this.showExtraParams = false;
                      this.showMarket = false;
                      this.showPlatform = false;
                      this.enableButton = false;
                      this.filterForm.patchValue({
                        "platform": -1,
                        "market": [],
                      }, { emitEvent: false })
                    }
                  }
                }
              }
              else {
                if (this.showDeliveryProfile) {
                  this.showExtraParams = false;
                  this.showDeliveryProfile = false;
                  this.showMarket = false;
                  this.showPlatform = false;
                  this.enableButton = false;
                  this.filterForm.patchValue({
                    "deliveryProfile": [],
                    "market": [],
                    "platform": -1,
                  }, { emitEvent: false })
                }
              }
            }
            else {
              if (this.showEnergyProducts) {
                this.showEnergyProducts = false;
                this.showExtraParams = false;
                this.showDeliveryProfile = false;
                this.showMarket = false;
                this.showPlatform = false;
                this.enableButton = false;
                this.filterForm.patchValue({
                  "energyProduct": [],
                  "deliveryProfile": [],
                  "market": [],
                  "platform": -1,
                }, { emitEvent: false })
              }
            }
            if (val.deliveryType == FilterConstants.deliveryTypeList[1].id) {
              this.enableButton = true;
            }
          }
          else {
            //daca e gaz
            if (val.commodity == FilterConstants.commodityList[1].id) {
              this.filters = {
                commodity: this.filterForm.get("commodity").value,
                market: this.filterForm.get("market").value.map(x => x.id),
                fromDate: this.createMonthYearModel(this.filterForm.get("startPeriodMonth").value, this.filterForm.get("startPeriodYear").value),
                toDate: this.createMonthYearModel(this.filterForm.get("endPeriodMonth").value, this.filterForm.get("endPeriodYear").value),
                platform: this.filterForm.get("platform").value,
                deliveryProfile: this.filterForm.get("deliveryProfile").value.map(x => x.id),
                product: this.filterForm.get("gasProduct").value
              }
              if (val.deliveryType == FilterConstants.deliveryTypeList[0].id) {
                if (!this.showGasProducts) {
                  this.getGasProductList(this.filters);
                }
                if (val.gasProduct.length != 0) {
                  if (!this.showPlatform) {
                    this.getPlatforms();
                  }
                  if (val.platform >= 0) {
                    this.getMarkets(this.filters);
                  }
                  if (val.market.length != 0) {
                    this.showExtraParams = true;
                    this.extraParamsList = FilterConstants.extraParamsList.filter(x => x.id % 2 == 0)
                  }
                  else {
                    this.showExtraParams = false;
                  }
                  if (this.showExtraParams) {
                    this.enableButton = true;
                  }
                  else {
                    this.enableButton = false;
                  }
                }
                else {
                  if (this.showPlatform) {
                    this.showExtraParams = false;
                    this.showMarket = false;
                    this.showPlatform = false;
                    this.enableButton = false;
                    this.filterForm.patchValue({
                      "platform": -1,
                      "market": [],
                    }, { emitEvent: false })
                  }
                }
              }
              else {
                if (this.showGasProducts) {
                  this.showGasProducts = false;
                  this.showExtraParams = false;
                  this.showMarket = false;
                  this.showPlatform = false;
                  this.enableButton = false;
                  this.filterForm.patchValue({
                    "gasProduct": [],
                    "market": [],
                    "platform": -1,
                  }, { emitEvent: false })
                }
              }
            }
            else {
              this.isBothCommodity = false;
            }
            if (val.deliveryType == FilterConstants.deliveryTypeList[1].id) {
              this.enableButton = true;
            }
          }
          if (val.commodity == 0) {
            this.isBothCommodity = true;
            this.filters = {
              commodity: 1,
              market: this.filterForm.get("market").value.map(x => x.id),
              fromDate: this.createMonthYearModel(this.filterForm.get("startPeriodMonth").value, this.filterForm.get("startPeriodYear").value),
              toDate: this.createMonthYearModel(this.filterForm.get("endPeriodMonth").value, this.filterForm.get("endPeriodYear").value),
              platform: this.filterForm.get("platform").value,
              deliveryProfile: this.filterForm.get("deliveryProfile").value.map(x => x.id),
              product: this.filterForm.get("energyProduct").value
            }
            this.gasFilters = {
              commodity: 2,
              market: this.filterForm.get("market").value.map(x => x.id),
              fromDate: this.createMonthYearModel(this.filterForm.get("startPeriodMonth").value, this.filterForm.get("startPeriodYear").value),
              toDate: this.createMonthYearModel(this.filterForm.get("endPeriodMonth").value, this.filterForm.get("endPeriodYear").value),
              platform: this.filterForm.get("platform").value,
              deliveryProfile: this.filterForm.get("deliveryProfile").value.map(x => x.id),
              product: this.filterForm.get("gasProduct").value
            }
            if (val.deliveryType == FilterConstants.deliveryTypeList[0].id) {
              if (!this.showEnergyProducts) {
                this.getEnergyProductList();
              }
              if (!this.showGasProducts) {
                this.getGasProductList(this.gasFilters);
              }
              if (val.energyProduct.length != 0 && val.gasProduct.length != 0) {
                if (!this.showDeliveryProfile) {
                  this.getDeliveryProfiles();
                }
                if (val.deliveryProfile.length != 0) {
                  if (!this.showPlatform) {
                    this.getPlatforms();
                  }
                  if (val.platform >= 0) {
                    this.getBothMarkets();
                  }
                  if (val.market.length != 0) {
                    this.showExtraParams = true;
                    this.extraParamsList = FilterConstants.extraParamsList
                  }
                  else {
                    this.showExtraParams = false;
                  }
                  if (this.showExtraParams) {
                    this.enableButton = true;
                  }
                  else {
                    this.enableButton = false;
                  }
                }
                else {
                  if (this.showPlatform) {
                    this.showExtraParams = false;
                    this.showMarket = false;
                    this.showPlatform = false;
                    this.enableButton = false;
                    this.filterForm.patchValue({
                      "platform": -1,
                      "market": [],
                    }, { emitEvent: false })
                  }
                }
              }
              else {
                if (this.showDeliveryProfile) {
                  this.showExtraParams = false;
                  this.showDeliveryProfile = false;
                  this.showMarket = false;
                  this.showPlatform = false;
                  this.enableButton = false;
                  this.filterForm.patchValue({
                    "deliveryProfile": [],
                    "market": [],
                    "platform": -1,
                  }, { emitEvent: false })
                }
              }
            }
            else {
              if (this.showEnergyProducts) {
                this.showEnergyProducts = false;
                this.showExtraParams = false;
                this.showDeliveryProfile = false;
                this.showMarket = false;
                this.showPlatform = false;
                this.enableButton = false;
                this.filterForm.patchValue({
                  "energyProduct": [],
                  "deliveryProfile": [],
                  "market": [],
                  "platform": -1,
                }, { emitEvent: false })
              }
            }
            if (val.deliveryType == FilterConstants.deliveryTypeList[1].id) {
              this.enableButton = true;
            }
          }
          else {
            this.isBothCommodity = false;
          }
        }
      }
    });
  }

  applyFilter() {
    if (this.enableButton) {
      this.extraParamsValues = this.filterForm.get("extraParams").value.map(x => x.id)
      this._loadData();
      this.filterForm.disable();
      this.isFormDisabled = true;
    }
  }

  resetFilter() {
    this.data = { "Gaz": [], "Energie": [], "ExtraParams": [], "ExtraParamsList": [] };
    this.filterForm.patchValue({
      startPeriodYear: -1,
      startPeriodMonth: -1,
      endPeriodYear: -1,
      endPeriodMonth: -1,
    });
    this.filterForm.patchValue({
      "gasProduct": [],
      "energyProduct": [],
      "deliveryProfile": [],
      "market": [],
      "platform": -1,
      "deliveryType": -1,
      "commodity": -1,
      "extraParams": []
    }, { emitEvent: false })
    this.showExtraParams = false;
    this.showDeliveryProfile = false;
    this.showMarket = false;
    this.showPlatform = false;
    this.enableButton = false;
    this.energyProductList = [];
    this.showData = false;
    this.showEnergyProducts = false;
    this.showGasProducts = false;
    this.isFormDisabled = false;
    this.filterForm.enable();
  }

  private getEnergyProductList() {
    this.filterService.getMonthlyProducts(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyProductList = entries;
        this.showEnergyProducts = true;
      });
  }

  private getGasProductList(filter: MonthlyDataFiltersModel) {
    this.filterService.getMonthlyProducts(filter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.gasProductList = entries;
        this.showGasProducts = true;
      });
  }

  private getDeliveryProfiles() {
    this.filterService.getMonthlyDeliveryProfiles(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.deliveryProfileList = FilterConstants.deliveryProfileList.filter(x => entries.includes(x.id));
        this.showDeliveryProfile = true;
      });
  }

  private getPlatforms() {
    this.filterService.getMonthlyPlatforms(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.platformList = FilterConstants.platformList.filter(x => entries.includes(x.id));
        this.showPlatform = true;
      });
  }

  private getMarkets(filters: MonthlyDataFiltersModel) {
    this.filterService.getMonthlyMarkets(filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.marketList = FilterConstants.marketList.filter(x => entries.includes(x.id));
        this.showMarket = true;
      });
  }

  private getBothMarkets() {
    forkJoin([
      this.filterService.getMonthlyMarkets(this.filters),
      this.filterService.getMonthlyMarkets(this.gasFilters)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([energyMarket, gasMarket]) => {
        this.marketList = FilterConstants.marketList.filter(x => energyMarket.includes(x.id));
        this.marketList = this.marketList.concat(FilterConstants.marketList.filter(x => gasMarket.includes(x.id)));
        this.showMarket = true;

      });
  }

  private createMonthYearModel(month, year): MonthYearModel {
    return { year: parseInt(year), month: parseInt(month) }
  }

}
