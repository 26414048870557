import { Component, OnInit } from '@angular/core';
import { StaticDataDto } from 'app/models/essential/StaticDataDto';
import { EssentialService } from 'app/services/essential.service';
import { SelfUnsubscriberBase } from 'app/shared/components/base/SelfUnsubscriberBase';
import { EChartsOption, SeriesModel } from 'echarts';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Colours } from 'app/constants/colors';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-scannerg-essential',
  templateUrl: './scannerg-essential.component.html',
  styleUrls: ['./scannerg-essential.component.scss']
})
export class ScannergEssentialComponent extends SelfUnsubscriberBase implements OnInit {

  energyPzuStatic: StaticDataDto;
  gasPzuStatic: StaticDataDto;
  gasNextMonthStatic: StaticDataDto;
  gasNextSeasonStatic: StaticDataDto;
  energyNextYearStatic: StaticDataDto;
  energyNextMonthStatic: StaticDataDto;
  loading = false;
  moment: any = moment;
  co2PetrolChartOption: EChartsOption
  pzuChartOption: EChartsOption
  nextMonthChartOptions: EChartsOption;
  nextYearChartOptions: EChartsOption;

  pzuChartLoading: boolean = false;

  constructor(private essentialService: EssentialService,
      private titleService: Title
    ) {
    super();
  }
  ngOnInit(): void {
    this.titleService.setTitle("partenerg | Esențial")
    this.loadData();
  }

  private loadData() {
    this.essentialService
      .getEnergyPzuEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyPzuStatic = entries;
      });
    this.essentialService
      .getGasPzuEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.gasPzuStatic = entries;
      });
    this.essentialService
      .getGasNextMonthEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.gasNextMonthStatic = entries;
      });
    this.essentialService
      .getEnergyNextYearEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyNextYearStatic = entries;
      });
    this.essentialService
      .getEnergyNextMonthEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyNextMonthStatic = entries;
      });
    this.essentialService
      .getGasNextSeasonEssentialStatic()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.gasNextSeasonStatic = entries;
      });
    this.essentialService
      .getCo2PetrolChartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.updateCo2PetrolChart(entries);
      });
    this.pzuChartLoading = true;
    this.essentialService
      .getPzuChartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.updatePzuChart(entries);
        this.pzuChartLoading = false;
      });
    this.essentialService
      .getNextMonthChartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.updateNextMonthChart(entries);
      });
    this.essentialService
      .getNextYearChartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.updateNextYearChart(entries);
      });
  }

  updateCo2PetrolChart(co2PetrolChartData) {
    this.co2PetrolChartOption = {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        data: ['Preț certificate CO2', 'Preț petrol'],
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
        }
      },
      grid: {
        left: '5%',
        right: '8%',
        bottom: '8%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: co2PetrolChartData.map(entry => moment(entry.date).format("DD-MM-YYYY"))
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
        }
      ],
      series: [
        {
          name: 'Preț certificate CO2',
          type: 'line',
          data: co2PetrolChartData.map(entry => entry.co2),
          label: {
            fontFamily: 'Arial',
            color: '#595959'
          },
        },
        {
          name: 'Preț petrol',
          type: 'line',
          label: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: co2PetrolChartData.map(entry => entry.petrol)
        },
      ],
      color: Colours.ColorPallete
    }
  }

  updatePzuChart(pzuChartData) {
    let dateDict = {};
    pzuChartData.naturalGas.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    pzuChartData.electricEnergy.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    pzuChartData.parametrii.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    var calculatedSeries = []
    if (pzuChartData.electricEnergy.length > 0) {
      calculatedSeries.push({
        name: 'Preț electricitate',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: pzuChartData.electricEnergy.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.indexValue])
      })
    }
    if (pzuChartData.naturalGas.length > 0) {
      calculatedSeries.push({
        name: 'Preț gaze',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: pzuChartData.naturalGas.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.indexValue])
      })
    }
    if (pzuChartData.parametrii.length > 0) {
      calculatedSeries.push({
        name: 'Procent înmagazinare gaze',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        yAxisIndex: 1,
        data: pzuChartData.parametrii.map(entry => entry.inmagazinareGazeNaturale != null ? [moment(entry.date).format("DD-MM-YYYY"), (entry.inmagazinareGazeNaturale * 100).toFixed(2)] : [moment(entry.date).format("DD-MM-YYYY"), null])
      });
      calculatedSeries.push({
        name: 'Procent umplere lacuri',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        yAxisIndex: 1,
        data: pzuChartData.parametrii.map(entry => entry.umplereLacuriAcumulare != null ? [moment(entry.date).format("DD-MM-YYYY"), (entry.umplereLacuriAcumulare * 100).toFixed(2)] : [moment(entry.date).format("DD-MM-YYYY"), null])
      })
    }
    this.pzuChartOption = {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: calculatedSeries.map(entry => entry.name)
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
        }
      },
      grid: {
        left: '5%',
        right: '8%',
        bottom: '8%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).map(entry => moment(entry).format("DD-MM-YYYY"))
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959',
          },
        },
        {
          type: 'value',
          min: 0,
          max: 100,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959',
            formatter: '{value} %'
          },
          position: 'right',
          axisLine: {
            show: true,
          },
        },
      ],
      series: calculatedSeries,
      color: Colours.ColorPallete
    }
  }

  updateNextMonthChart(nextMonthChartData) {
    let dateDict = {};
    nextMonthChartData.electricEnergyLocal.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextMonthChartData.electricEnergyExtern.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextMonthChartData.naturalGasExtern.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextMonthChartData.naturalGasLocal.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    var calculatedSeries = []
    if (nextMonthChartData.electricEnergyLocal.length > 0) {
      calculatedSeries.push({
        name: 'Preț electricitate (local)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextMonthChartData.electricEnergyLocal.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    if (nextMonthChartData.electricEnergyExtern.length > 0) {
      calculatedSeries.push({
        name: 'Preț electricitate (extern)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextMonthChartData.electricEnergyExtern.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    if (nextMonthChartData.naturalGasLocal.length > 0) {
      calculatedSeries.push({
        name: 'Preț gaze (local)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextMonthChartData.naturalGasLocal.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      });
    }
    if (nextMonthChartData.naturalGasExtern.length > 0) {
      calculatedSeries.push({
        name: 'Preț gaze (extern)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextMonthChartData.naturalGasExtern.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    this.nextMonthChartOptions = {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        data: calculatedSeries.map(entry => entry.name),
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
        }
      },
      grid: {
        left: '5%',
        right: '8%',
        bottom: '8%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(entry => moment(entry).format("DD-MM-YYYY"))
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
        },
      ],
      series: calculatedSeries,
      color: Colours.ColorPallete
    }
  }

  updateNextYearChart(nextYearChartData) {
    let dateDict = {};
    nextYearChartData.electricEnergyLocal.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextYearChartData.electricEnergyExtern.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextYearChartData.naturalGasExtern.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    nextYearChartData.naturalGasLocal.forEach(entry => {
      if (Array.isArray(dateDict[entry.date.split("T")[0]])) {
        dateDict[entry.date.split("T")[0]].push(entry)
      }
      else {
        dateDict[entry.date.split("T")[0]] = [entry]
      }
    })
    var calculatedSeries = []
    if (nextYearChartData.electricEnergyLocal.length > 0) {
      calculatedSeries.push({
        name: 'Preț electricitate (local)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextYearChartData.electricEnergyLocal.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    if (nextYearChartData.electricEnergyExtern.length > 0) {
      calculatedSeries.push({
        name: 'Preț electricitate (extern)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextYearChartData.electricEnergyExtern.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    if (nextYearChartData.naturalGasLocal.length > 0) {
      calculatedSeries.push({
        name: 'Preț gaze (local)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextYearChartData.naturalGasLocal.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      });
    }
    if (nextYearChartData.naturalGasExtern.length > 0) {
      calculatedSeries.push({
        name: 'Preț gaze (extern)',
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: nextYearChartData.naturalGasExtern.map(entry => [moment(entry.date).format("DD-MM-YYYY"), entry.value])
      })
    }
    this.nextYearChartOptions = {
      // title: {
      //   text: 'CO2 și Petrol'
      // },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        data: calculatedSeries.map(entry => entry.name),
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
        }
      },
      grid: {
        left: '5%',
        right: '8%',
        bottom: '8%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(entry => moment(entry).format("DD-MM-YYYY"))
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
        },
      ],
      series: calculatedSeries,
      color: Colours.ColorPallete
    }
  }
}
