import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-post',
    templateUrl: './blog-post.component.html',
    styleUrls: ['./blog-post.component.scss']
})

export class BlogPostComponent implements OnInit {
    blogArticle: string = `
    <h2>Prin Ochii Unui Consumator Industrial</h2>
    <h3>Implicațiile Situației Actuale De Pe Piața De Energie</h3>
    <div class="row">
    <img src='https://source.unsplash.com/600x400/?computer' alt="Circle Image"
                        class="blog-image img-no-padding img-responsive">
    </div>
    `
    constructor() { }
    ngOnInit() { }

}
