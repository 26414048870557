import { Component, OnInit } from "@angular/core";
import { electricEnergyProductDto } from "app/models/electricEnergy/electricEnergyPricesProductDto";
import { DailyDataFiltersModel } from "app/models/scannergFilterModels/dailyDataFiltersModel";
import { ElectricEnergyService } from "app/services/electric-energy.service";
import { LoadableComponentBase } from "app/shared/components/ag-grid/LoadableComponentBase";
import * as moment from "moment";
import { distinct, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-scannerg-prices-electric-energy-table",
  templateUrl: "./scannerg-prices-electric-energy-table.component.html",
  styleUrls: ["./scannerg-prices-electric-energy-table.component.scss"],
})
export class ScannergPricesElectricEnergyTableComponent
  extends LoadableComponentBase
  implements OnInit {

  moment: any = moment;
  queueRecords: electricEnergyProductDto[];
  filters: DailyDataFiltersModel;
  loading: boolean = false;

  constructor(private energyService: ElectricEnergyService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.energyService
      .getEnergyPricesProducts(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.queueRecords = entries;
        this.loading = false;
      });
  }
}
