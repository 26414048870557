import { Component, OnInit } from '@angular/core';
import { FilterConstants } from 'app/constants/filterConstants';
import { EnumModel } from 'app/models/scannergFilterModels/enumModel';
import { FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { takeUntil } from 'rxjs/operators';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { StackedPzuEnergyFiltersModel } from 'app/models/scannergFilterModels/pzuEnergyStackedFiltersModel';
import { dayMonthValidator } from 'app/validators/dayMonthValidator';

@Component({
  selector: 'app-scannerg-advanced-pzu-electric-stacked-period',
  templateUrl: './scannerg-advanced-pzu-electric-stacked-period.component.html',
  styleUrls: ['./scannerg-advanced-pzu-electric-stacked-period.component.scss']
})

export class ScannergAdvancedPzuElectricStackedPeriodComponent extends LoadableComponentBase implements OnInit {

  data;
  gridData = [];
  focus: any;
  focus1: any;
  enableSubmit: boolean = false;
  loading: boolean = false;

  monthList = [
    { value: 1, name: "Ianuarie" },
    { value: 2, name: "Februarie" },
    { value: 3, name: "Martie" },
    { value: 4, name: "Aprilie" },
    { value: 5, name: "Mai" },
    { value: 6, name: "Iunie" },
    { value: 7, name: "Iulie" },
    { value: 8, name: "August" },
    { value: 9, name: "Septembrie" },
    { value: 10, name: "Octombrie" },
    { value: 11, name: "Noiembrie" },
    { value: 12, name: "Decembrie" },
  ]
  startPeriodDays: number[] = [];
  endPeriodDays: number[] = [];
  periodYears = [{ value: 1, name: "Un an" }, { value: 2, name: "Doi ani" }, { value: 3, name: "Trei ani" }]
  years: number[] = [2022, 2021, 2020, 2019]
  //filter constants
  intervalList: EnumModel[] = FilterConstants.pzuIntervalList;
  showData: boolean = false;

  //form
  filterForm: FormGroup;
  filters: StackedPzuEnergyFiltersModel;

  constructor(
    private energyService: ElectricEnergyService,
  ) {
    super();

  }
  marketDropdownList = [];
  selectedItems = [];
  marketDropdownSettings: IDropdownSettings = {};
  productDropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.filterForm = new FormGroup({
      interval: new FormControl(-1),
      startPeriodDay: new FormControl(-1),
      startPeriodMonth: new FormControl(-1),
      endPeriodDay: new FormControl(-1),
      endPeriodMonth: new FormControl(-1),
    }, { validators: [dayMonthValidator] })
    this.onChanges();
  }

  private _loadData() {
    this.loading = true;
    this.energyService.getFilteredStackedPzuElectricEnergy(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.showData = true;
        entries.forEach(element => {
          this.gridData = this.gridData.concat(element.entries)
        });
        this.loading = false;
      });

  }

  onChanges(): void {
    this.filterForm.valueChanges.subscribe(val => {
      if (val.startPeriodMonth != -1) {
        this.startPeriodDays = this.getMonthDays(val.startPeriodMonth)
      }
      if (val.endPeriodMonth != -1) {
        this.endPeriodDays = this.getMonthDays(val.endPeriodMonth)
      }
      if (val.startPeriodMonth != -1 && val.startPeriodDay != -1 && val.endPeriodDay != -1 && val.endPeriodMonth != -1 && val.interval != -1) {
        this.enableSubmit = true;
      }
      else {
        this.enableSubmit = false;
      }
    });
  }

  applyFilter() {
    if (this.enableSubmit) {
      this.filters = {
        interval: this.filterForm.get("interval").value,
        fromDate: { month: this.filterForm.get("startPeriodMonth").value, day: this.filterForm.get("startPeriodDay").value },
        toDate: { month: this.filterForm.get("endPeriodMonth").value, day: this.filterForm.get("endPeriodDay").value }
      }
      this._loadData();
      this.filterForm.disable();
      this.enableSubmit = false;
    }
  }

  resetFilter() {
    this.data = undefined;
    this.filterForm.patchValue({
      "startPeriodMonth": null,
      "startPeriodDay": null,
      "endPeriodMonth": null,
      "endPeriodDay": null,
      "interval": 0
    })
    this.showData = false;
    this.filterForm.enable();
  }

  private formatDate(date: any) {
    if (date.month.toString().length < 2) {
      date.month = "0" + date.month;
    }
    if (date.day.toString().length < 2) {
      date.day = "0" + date.day;
    }
    return `${date.year}-${date.month}-${date.day}`;
  }

  private getMonthDays(monthNumber: number) {
    const months31: number[] = [1, 3, 5, 7, 8, 10, 12];
    const months30: number[] = [4, 6, 9, 11];
    if (months31.filter(entry => entry == monthNumber).length != 0) {
      return [...Array(32).keys()].slice(1)
    }
    if (months30.filter(entry => entry == monthNumber).length != 0) {
      return [...Array(31).keys()].slice(1)
    }
    if (monthNumber == 2) {
      return [...Array(30).keys()].slice(1)
    }
  }
}
