import { AbstractControl, ValidationErrors } from "@angular/forms";

export function dateValidator(c: AbstractControl): ValidationErrors | null {
    const deliveryStart = c.get('deliveryStart').value;
    const deliveryEnd = c.get('deliveryEnd').value;
    if (!deliveryStart || !deliveryEnd) {
        return null;
    }
    if (deliveryStart.year > deliveryEnd.year) {
        return { datesError: true };
    }
    if (deliveryStart.year == deliveryEnd.year) {
        if (deliveryStart.month > deliveryEnd.month) {
            return { datesError: true };
        }
        if (deliveryStart.month == deliveryEnd.month) {
            if (deliveryStart.day >= deliveryEnd.day) {
                return { datesError: true };
            }
        }
    }
    return null;
};
