import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-scannerg-volumes',
  templateUrl: './scannerg-volumes.component.html',
  styleUrls: ['./scannerg-volumes.component.scss']
})
export class ScannergVolumesComponent implements OnInit {

  constructor(private titleService: Title
    ) {
  }
  ngOnInit(): void {
    this.titleService.setTitle("partenerg | Volume")
  }

}
