import { Injectable } from "@angular/core";
import { LegalPressDto } from "app/models/legalPress/legalPressDto";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class LegalPressService {
    private _apiUserUrl = ApiConstants.LEGAL_PRESS_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getAllLegalPress(): Observable<LegalPressDto[]> {
        return this.apiService.get<LegalPressDto[]>(`${this._apiUserUrl}/press`);
    }

    downloadFile(): Observable<any> {
        return this.apiService.get<any>(`${this._apiUserUrl}/download`)
    }

    addLegalPress(newLegalPress: LegalPressDto): Observable<LegalPressDto> {
        return this.apiService.post<LegalPressDto>(`${this._apiUserUrl}`, newLegalPress)
    }

    addLegalPressFile(legalPressId: number, file: FormData): Observable<LegalPressDto> {
        return this.apiService.put<LegalPressDto>(`${this._apiUserUrl}/uploadFile/${legalPressId}`, file)
    }

    deleteLegalPressFile(legalPressId: number): Observable<LegalPressDto> {
        return this.apiService.delete<LegalPressDto>(`${this._apiUserUrl}/${legalPressId}`)
    }
}