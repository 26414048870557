import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LegalPressDto } from 'app/models/legalPress/legalPressDto';
import { DialogService } from 'app/services/dialog.service';
import { LegalPressService } from 'app/services/legal-press.service';
import { SelfUnsubscriberBase } from 'app/shared/components/base/SelfUnsubscriberBase';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-pdf-card-admin',
    templateUrl: './pdf-card-admin.component.html',
    styleUrls: ['./pdf-card-admin.component.scss']
})

export class PdfCardAdminComponent extends SelfUnsubscriberBase implements OnInit {

    @Output() showMorePressed = new EventEmitter();
    @Input() inputData: LegalPressDto;
    @Output() changed = new EventEmitter();

    constructor(private router: Router,
        private dialogService: DialogService,
        private legalPressService: LegalPressService
    ) {
        super();
    }

    ngOnInit() { }

    onClickBlogCard() {
        this.router.navigateByUrl('/blog-post');
    }

    moreInfoClicked() {
        window.open("api/legalPress/" + this.inputData.path, '_blank');
        // window.open(this.inputData.path, '_blank');
    }

    deletePdf() {
        this.dialogService
            .showDialog({
                title: `Ești sigur că vrei să ștergi acest fișier?`,
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#18ce0f",
                confirmButtonText: "Confirmă",
            })
            .then((result) => {
                if (!result.value) return;
                this.legalPressService.deleteLegalPressFile(this.inputData.id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.changed.emit();
                    })
            });
    }
}
