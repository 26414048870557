import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthenticationService } from 'app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar-transparent',
    templateUrl: './navbar-transparent.component.html',
    styleUrls: ['./navbar-transparent.component.scss']
})
export class NavbarTransparentComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    public isLoggedIn: boolean;
    public name: string;
    isAdministrator: boolean = false;
    @Input() isWhiteLogo: boolean = true;

    constructor(public location: Location,
        private element: ElementRef,
        private authenticationService: AuthenticationService,
        private _router: Router,
        private eRef: ElementRef) {
        this.sidebarVisible = false;
        this.authenticationService.authChanged
            .subscribe(res => {
                this.isLoggedIn = res;
            })
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            if (this.sidebarVisible) {
                this.sidebarClose();
            }
        }
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (this.authenticationService.isUserAuthenticated()) {
            this.isLoggedIn = true;
        }
        if (this.isLoggedIn) {
            this.name = localStorage.getItem("name");
        }
        this.authenticationService.getCurrentRole().subscribe(data => {
            if (data.name === "Administrator") {
                this.isAdministrator = true;
            }
        })
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/signin' || titlee === '/signup') {
            return true;
        }
        else {
            return false;
        }
    }
    onLogout() {
        this.authenticationService.logout();
        this._router.navigate(["/"]);
    }

    scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
}
