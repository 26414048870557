import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scannerg-advanced-stacked-period',
  templateUrl: './scannerg-advanced-stacked-period.component.html',
  styleUrls: ['./scannerg-advanced-stacked-period.component.scss']
})
export class ScannergAdvancedStackedPeriodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
