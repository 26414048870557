import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-event-info-content',
    templateUrl: './event-modal-content.component.html'
})
export class EventInfoModalContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) { }
}