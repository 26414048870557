import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Colours } from 'app/constants/colors';
import { ExtraParamsDto } from 'app/models/ExtraParameters/extraParamsDto';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';

@Component({
  selector: 'app-petrol-chart',
  templateUrl: './petrol-chart.component.html',
  styleUrls: ['./petrol-chart.component.scss']
})
export class PetrolChartComponent implements OnInit {

  @Input() inputData;
  chartOption: EChartsOption;
  mapChartOption: EChartsOption;
  myChart: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData: ExtraParamsDto[] = changes.inputData.currentValue
    if (!changedData) return;
    let calculatedSeries = [];

    calculatedSeries.push(
      {
        name: "Preț",
        type: 'line',
        axisLabel: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: changedData.map(entry => [moment(entry.date).format('DD-MM-YYYY'), entry.petrol])
      },
    )
    this.chartOption = {
      title: {
        text: 'Petrol',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        }
      },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        bottom: '1%',
        data: calculatedSeries.map(entry => entry.name)
      },
      toolbox: {
        feature: {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: 'Petrol'
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "17%",
        containLabel: true
      },
      dataZoom: [
        {
          left: "7%",
          right: "10%",
          bottom: 40,
          textStyle: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          backgroundColor: '#B5F1FF',
          dataBackground: {
            lineStyle: {
              color: '#003A47'
            },
            // areaStyle: {
            //   color: '#00758F'
            // }
          },
          borderColor: '#003A47',
          fillerColor: '#47C6A3'
        },
        {
          type: "inside"
        }
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: changedData.map(entry => moment(entry.date).format('DD-MM-YYYY'))
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          position: 'left',
          axisLine: {
            show: true,
          },
        },
      ],
      series: calculatedSeries,
      color: Colours.ColorPallete
    };
  }
}
