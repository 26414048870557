import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UserForRegistrationDto } from 'app/models/user/userForRegistrationDto';
import { AuthenticationService } from 'app/services/authentication.service';
import { forbiddenCharactersValidator, mustMatch, strong } from '../validators/signup.validators';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test: Date = new Date();
    focus;
    created = false;
    focus1;
    loading: boolean = false;
    public registerForm: FormGroup;
    constructor(private authenticationService: AuthenticationService,
        private titleService: Title
        ) { }

    ngOnInit(): void {
        this.titleService.setTitle("partenerg | Cont nou")
        this.registerForm = new FormGroup({
            firstName: new FormControl('', [forbiddenCharactersValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/), Validators.required]),
            lastName: new FormControl('', [forbiddenCharactersValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/), Validators.required]),
            company: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
            password: new FormControl('', [Validators.required]),
            confirm: new FormControl('', [Validators.required])
        },
            mustMatch("password", "confirm")
        );
    }
    public validateControl = (controlName: string) => {
        return this.registerForm.controls[controlName].invalid && this.registerForm.controls[controlName].touched
    }
    public hasError = (controlName: string, errorName: string) => {
        return this.registerForm.controls[controlName].hasError(errorName)
    }

    public registerUser = (registerFormValue) => {
        const formValues = { ...registerFormValue };
        const user: UserForRegistrationDto = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            company: formValues.company,
            email: formValues.email,
            password: formValues.password,
            confirmPassword: formValues.confirm
        };
        this.loading = true;
        this.authenticationService.registerUser(user)
            .subscribe(_ => {
                console.log("Successful registration");
                this.loading = false;
                this.created = true;
            },
                error => {
                    //alert(error.error.errors);
                    console.log(error.error.errors);
                })
    }
}
