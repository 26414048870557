import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserForTableDto } from 'app/models/user/userForTableDto';
import { DataService } from 'app/services/data.service';
import { DialogService } from 'app/services/dialog.service';
import { UserService } from 'app/services/user.service';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import { SelfUnsubscriberBase } from 'app/shared/components/base/SelfUnsubscriberBase';
import { clone } from 'app/shared/components/base/Utils';
import { takeUntil } from 'rxjs/operators';
import { ActivateUserModalContent } from '../activate-user-modal-content/activate-user-modal-content.component';

@Component({
  selector: 'app-clients-page',
  templateUrl: './clients-page.component.html',
  styleUrls: ['./clients-page.component.scss']
})

export class ManageClientPageComponent extends GridComponentBase<UserForTableDto> implements SelfUnsubscriberBase {

  disableButton: boolean = false;

  constructor(
    private userService: UserService,
    private dataService: DataService,
    private dialogService: DialogService,
    private modalService: NgbModal
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.registrationDate.split("T")[0],
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,

      },
      {
        field: "lastName",
        headerName: "Nume",
        width: 200,
        suppressSizeToFit: false,
      },
      {
        field: "firstName",
        headerName: "Prenume",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        field: "company",
        headerName: "Companie",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        field: "email",
        headerName: "Email",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => x.data.role ? x.data.role : "Inactiv",
        headerName: "Tip cont",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => x.data.role != "Administrator" ? x.data.activeUntilDateTime.split("T")[0] : null,
        headerName: "Activ până la",
        width: 170,
        suppressSizeToFit: false,
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.userService.getUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.data = data;
      });
  }

  onEdit() {
    this.dialogService
      .showDialog({
        title: `Are you sure you want to accept this benefit request?`,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#18ce0f",
        confirmButtonText: "Accept",
      })
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;


    this.currentSelection = clone($event.data);
    // this.currentSelectionIdx = this.data.indexOf(
    //   this.data.find((u) => u.id == this.currentSelection.id)
    // );

  }

  onRowDoubleClicked(_: any): void {
    this.openModal();
  }

  validateModel(prop: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  updateData() {
    this.disableButton = true;
    this.dataService.updateData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.disableButton = false;

      });
  }

  openModal() {
    const modalRef = this.modalService.open(ActivateUserModalContent);
    modalRef.componentInstance.user = this.currentSelection;
    modalRef.componentInstance.changed.subscribe(x => this.loadData());
  }


}
