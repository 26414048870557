import { ButtonCellRendererComponent } from "./button-cell-renderer/button-cell-renderer.component";
import {
  DateFormatter,
  DecimalFormatter,
  PercentageFormatter,
} from "./Formatters";
import { SetFilterComponent } from "./set-filter/set-filter.component";

export class AgGridUtils {
  public static addDefaultColumnTypes(columnTypes: any) {
    columnTypes["dateColumn"] = this.getDateColumn();

    columnTypes["numberColumn"] = this.getNumberColumn();
    columnTypes["decimalColumn"] = this.getDecimalColumn(2);
    columnTypes["percentageColumn"] = this.getPercentageColumn(2);
    columnTypes["cogColumn"] = this.getDecimalColumn(3);
    columnTypes["weightColumn"] = this.getDecimalColumn(4);

    columnTypes["setColumn"] = this.getSetColumn();
    columnTypes["centerColumn"] = this.getCenterColumn();
    columnTypes["buttonColumn"] = this.getButtonColumn();
    columnTypes["wrapColumn"] = this.getWrapColumn();
  }

  public static getDateColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: "dateRangeFilter",
      valueFormatter: DateFormatter(),
      resizable: false,
      cellClass: "text-center",
      minWidth: 110,
      width: 110,
      cellRenderer: cellRendererFunc,
    };
  }

  public static getNumberColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: "agNumberColumnFilter",
      cellClass: "text-right",
      cellRenderer: cellRendererFunc,
    };
  }

  public static getDecimalColumn(
    numberOfDecimals: number,
    cellRendererFunc?: (d: { value: any }) => any
  ) {
    return {
      filter: "agNumberColumnFilter",
      cellClass: "text-right",
      valueFormatter: DecimalFormatter(numberOfDecimals),
      cellRenderer: cellRendererFunc,
    };
  }

  public static getPercentageColumn(
    numberOfDecimals: number,
    cellRendererFunc?: (d: { value: any }) => any
  ) {
    return {
      filter: "agNumberColumnFilter",
      cellClass: "text-right",
      valueFormatter: PercentageFormatter(numberOfDecimals),
      cellRenderer: cellRendererFunc,
    };
  }

  public static getSetColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: "setFilter",
      cellRenderer: cellRendererFunc,
    };
  }

  public static getCenterColumn(cellRendererFunc?: (value: any) => any) {
    return {
      cellClass: "text-center",
      headerClass: "text-center",
      cellRenderer: cellRendererFunc,
    };
  }

  public static getWrapColumn() {
    return {
      cellClass: "text-wrap",
    };
  }

  public static getButtonColumn() {
    return {
      cellRenderer: "buttonCellRenderer",
      minWidth: 100,
      width: 200,
      maxWidth: 200,
      filter: false,
      sortable: false,
      resizable: false,
    };
  }

  public static setFixedWidth(column: any, width: number) {
    column.width = width;
    column.minWidth = width;
    column.maxWidth = width;
    column.resizable = false;
  }

  public static returnFirstValue(params) {
    return params.data[0].projectName
  }

  public static gridFilters = {
    setFilter: SetFilterComponent,
    // dateRangeFilter: DateRangeFilterComponent
  };

  public static gridRenderers = {
    buttonCellRenderer: ButtonCellRendererComponent,
  };
}
