import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FilterConstants } from 'app/constants/filterConstants';
import { ElectricEnergyDto } from 'app/models/electricEnergy/electricEnergyDto';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-gas-grid',
  templateUrl: './gas-grid.component.html',
  styleUrls: ['./gas-grid.component.scss']
})
export class GasGridComponent extends GridComponentBase<ElectricEnergyDto> implements OnInit {

  @Input() inputData;

  constructor() {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date,
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('DD-MM-YYYY')
        }
      },
      {
        valueGetter: x => ((FilterConstants.deliveryTypeList.filter(profile => profile.id == x.data.deliveryType)[0].name)),
        headerName: "Tip Livrare",
        width: 170,
        suppressSizeToFit: false,

      },
      {
        valueGetter: x => ((FilterConstants.deliveryProfileList.filter(profile => profile.id == x.data.deliveryProfile)[0].name)),
        headerName: "Profil Livrare",
        width: 170,
        suppressSizeToFit: false,

      },
      {
        field: "product",
        headerName: "Produs",
        width: 200,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => ((FilterConstants.platformList.filter(profile => profile.id == x.data.platform)[0].name)),
        headerName: "Platforma",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => ((FilterConstants.marketList.filter(profile => profile.id == x.data.market)[0].name)),
        headerName: "Piata",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        valueGetter: x => x.data.indexValue.toFixed(2),
        headerName: "Pret tranzactionare",
        width: 300,
        suppressSizeToFit: false,
      }
      // {
      //   field: "seller",
      //   headerName: "Vanzator",
      //   width: 300,
      //   suppressSizeToFit: false,
      // },
      // {
      //   field: "buyer",
      //   headerName: "Cumparator",
      //   width: 300,
      //   suppressSizeToFit: false,
      // },

    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData: ElectricEnergyDto[] = changes.inputData.currentValue
    if (!changedData) return;
    this.data = changedData;
  }


  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
