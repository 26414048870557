import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MonthlyNaturalGasEnergyQuotesDto } from 'app/models/naturalGas/monthlyNaturalGasQuotesDto';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-echilibrare-natural-gas-grid',
  templateUrl: './echilibrare-natural-gas-grid.component.html',
  styleUrls: ['./echilibrare-natural-gas-grid.component.scss']
})
export class EchilibrareNaturalGasGridComponent extends GridComponentBase<MonthlyNaturalGasEnergyQuotesDto> implements OnInit {

  @Input() isGas;
  @Input() inputData;

  constructor() { 
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T").join(" "),
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('MM-YYYY')
      }
      },
      {
        field: "volumDeficitEchilibrare",
        headerName: "Volum Deficit",
        width: 200,
        suppressSizeToFit: false,
      },
      {
        field: "pretMediuDeficitEchilibrare",
        headerName: "Pret Deficit",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        field: "volumExcedentEchilibrare",
        headerName: "Volum Excedent",
        width: 170,
        suppressSizeToFit: false,
      },
      {
        field: "pretMediuExcedentEchilibrare",
        headerName: "Pret Excedent",
        width: 170,
        suppressSizeToFit: false,
      },

    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData: MonthlyNaturalGasEnergyQuotesDto[] = changes.inputData.currentValue
    if (!changedData) return;
    this.data = changedData;
  }


  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
