import { AbstractControl, ValidationErrors } from "@angular/forms";

export function dayMonthValidator(c: AbstractControl): ValidationErrors | null {
    const startPeriodMonth = c.get('startPeriodMonth').value;
    const startPeriodDay = c.get('startPeriodDay').value;
    const endPeriodMonth = c.get('endPeriodMonth').value;
    const endPeriodDay = c.get('endPeriodDay').value;


    if (!endPeriodDay || !endPeriodMonth || !startPeriodDay || !startPeriodMonth) {
        return null;
    }
    if (endPeriodDay == -1 || endPeriodMonth == -1 || startPeriodDay == -1 || startPeriodMonth == -1) {
        return null;
    }
    if (startPeriodMonth > endPeriodMonth) {
        return { datesError: true };
    }
    if (startPeriodMonth == endPeriodMonth) {
        if (startPeriodDay >= endPeriodDay) {
            return { datesError: true };
        }
    }
    return null;
};
