import { Component, OnInit } from '@angular/core';
import { electricEnergyProductDto } from 'app/models/electricEnergy/electricEnergyPricesProductDto';
import { DailyDataFiltersModel } from 'app/models/scannergFilterModels/dailyDataFiltersModel';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-prices-natural-gas-table',
  templateUrl: './scannerg-prices-natural-gas-table.component.html',
  styleUrls: ['./scannerg-prices-natural-gas-table.component.scss']
})
export class ScannergPricesNaturalGasTableComponent extends LoadableComponentBase implements OnInit {

  moment: any = moment;
  queueRecords: electricEnergyProductDto[];
  filters: DailyDataFiltersModel;
  loading: boolean = false;

  constructor(private gasService: NaturalGasService) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.gasService.getGasPricesProducts(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.queueRecords = entries;
        this.loading = false;
      });
  }
}
