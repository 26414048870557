import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scannerg-press',
  templateUrl: './scannerg-press.component.html',
  styleUrls: ['./scannerg-press.component.scss']
})
export class ScannergPressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
