import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() { }

  onLinkedInIcon() {
    window.open("https://www.linkedin.com/in/theodor-livinschi-12002b42/", '_blank');
  }
}
