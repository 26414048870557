import { Injectable } from "@angular/core";
import { ElectricEnergyDto } from "app/models/electricEnergy/electricEnergyDto";
import { ElectricEnergyChartDto } from "app/models/electricEnergy/electricEnergyPricesChartDto";
import { electricEnergyProductDto } from "app/models/electricEnergy/electricEnergyPricesProductDto";
import { EuropePzuModel } from "app/models/electricEnergy/europePzuModel";
import { ElectricEnergyMonthlyQuotes } from "app/models/electricEnergy/monthlyEnergyQuotes";
import { MonthlyElectricEnergyQuotesDto } from "app/models/electricEnergy/monthlyElectricEnergyQuotesDto";
import { PzuElectricEnergyDto } from "app/models/electricEnergy/pzuElectricEnergyDto";
import { StackedElectricEnergyDto } from "app/models/electricEnergy/stackedElectricEnergyDto";
import { StackedMonthlyElectricEnergyDto } from "app/models/electricEnergy/stackedMonthlyElectricEnergyDto";
import { StackedPzuElectricEnergyDto } from "app/models/electricEnergy/stackedPzuElectricEnergyDto";
import { FutureDeliveryMonitoringModel } from "app/models/monitoring/futureDeliveryMonitoringModel";
import { ProducersModel } from "app/models/producers/producersModel";
import { ProvidersModel } from "app/models/providers/providersModel";
import { DailyDataFiltersModel } from "app/models/scannergFilterModels/dailyDataFiltersModel";
import { DailyDataStackedFiltersModel } from "app/models/scannergFilterModels/dailyDataStackedFiltersModel";
import { EnergyTimeButtonsFilter } from "app/models/scannergFilterModels/energyFilterTimeButtonsModel";
import { MonthlyDataFiltersModel } from "app/models/scannergFilterModels/monthlyDataFiltersModel";
import { MonthlyDataStackedFiltersModel } from "app/models/scannergFilterModels/monthlyDataStackedFiltersModel";
import { PzuEnergyFiltersModel } from "app/models/scannergFilterModels/pzuEnergyFiltersModel";
import { StackedPzuEnergyFiltersModel } from "app/models/scannergFilterModels/pzuEnergyStackedFiltersModel";
import { SourcesModel } from "app/models/sources/sourcesModel";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class ElectricEnergyService {
    private _apiUserUrl = ApiConstants.ELECTRIC_ENERGY_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getFilteredElectricEnergy(filters: DailyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(this._apiUserUrl, filters);
    }

    getMonthlyFilteredElectricEnergy(filters: MonthlyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/monthlyData`, filters);
    }

    getFilteredDailyPzuElectricEnergy(filters: DailyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/dailyPzu`, filters);
    }

    getFilteredMonthlyPzuElectricEnergy(filters: MonthlyDataFiltersModel): Observable<ElectricEnergyDto[]> {
        return this.apiService.post<ElectricEnergyDto[]>(`${this._apiUserUrl}/monthlyPzu`, filters);
    }

    getFilteredPzuElectricEnergy(filters: PzuEnergyFiltersModel): Observable<PzuElectricEnergyDto[]> {
        return this.apiService.post<PzuElectricEnergyDto[]>(`${this._apiUserUrl}/pzuData`, filters);
    }

    getStackedElectricEnergy(filters: DailyDataStackedFiltersModel): Observable<StackedElectricEnergyDto[]> {
        return this.apiService.post<StackedElectricEnergyDto[]>(`${this._apiUserUrl}/stackedEnergy`, filters);
    }

    getStackedDailyPzuElectricEnergy(filters: DailyDataStackedFiltersModel): Observable<StackedElectricEnergyDto[]> {
        return this.apiService.post<StackedElectricEnergyDto[]>(`${this._apiUserUrl}/stackedEnergy/dailyPzu`, filters);
    }

    getStackedElectricEnergyMonthly(filters: MonthlyDataStackedFiltersModel): Observable<StackedMonthlyElectricEnergyDto[]> {
        return this.apiService.post<StackedMonthlyElectricEnergyDto[]>(`${this._apiUserUrl}/stackedEnergy/monthlyData`, filters);
    }

    getStackedElectricEnergyPzuMonthly(filters: MonthlyDataStackedFiltersModel): Observable<StackedMonthlyElectricEnergyDto[]> {
        return this.apiService.post<StackedMonthlyElectricEnergyDto[]>(`${this._apiUserUrl}/stackedEnergy/pzuMonthlyData`, filters);
    }

    getFilteredStackedPzuElectricEnergy(filters: StackedPzuEnergyFiltersModel): Observable<StackedPzuElectricEnergyDto[]> {
        return this.apiService.post<StackedPzuElectricEnergyDto[]>(`${this._apiUserUrl}/stackedPzuData`, filters);
    }

    getEnergyPricesProducts(filters: DailyDataFiltersModel): Observable<electricEnergyProductDto[]> {
        return this.apiService.post<electricEnergyProductDto[]>(`${this._apiUserUrl}/priceEnergy`, filters);
    }

    getEnergyDataChart(filters: EnergyTimeButtonsFilter): Observable<any> {
        return this.apiService.post<any>(`${this._apiUserUrl}/priceEnergy/nextShipmentChart`, filters);
    }

    getEnergyMonthlyQuotes(filters: EnergyTimeButtonsFilter): Observable<ElectricEnergyMonthlyQuotes[]> {
        return this.apiService.post<ElectricEnergyMonthlyQuotes[]>(`${this._apiUserUrl}/monthlyQuotes`, filters);
    }

    getEnergyProducersMonthly(filters: EnergyTimeButtonsFilter): Observable<ProducersModel[]> {
        return this.apiService.post<ProducersModel[]>(`${this._apiUserUrl}/producers`, filters);
    }

    getEnergySourcesMonthly(filters: EnergyTimeButtonsFilter): Observable<SourcesModel[]> {
        return this.apiService.post<SourcesModel[]>(`${this._apiUserUrl}/sources`, filters);
    }

    getEnergyProviders(filters: EnergyTimeButtonsFilter): Observable<ProvidersModel[]> {
        return this.apiService.post<ProvidersModel[]>(`${this._apiUserUrl}/providers`, filters);
    }

    getEuropePzu(): Observable<EuropePzuModel[]> {
        return this.apiService.get<EuropePzuModel[]>(`${this._apiUserUrl}/europePzu`);
    }

    getEnergyPZUDataChart(filters: PzuEnergyFiltersModel): Observable<any> {
        return this.apiService.post<any>(`${this._apiUserUrl}/priceEnergy/PZUchart`, filters);
    }

    getEnergyMonthlyQuotesDataChart(filters: PzuEnergyFiltersModel): Observable<any> {
        return this.apiService.post<MonthlyElectricEnergyQuotesDto[]>(`${this._apiUserUrl}/priceEnergy/MonthlyQuotes`, filters);
    }
}