import { Component, OnInit } from '@angular/core';
import { PzuEnergyFiltersModel } from 'app/models/scannergFilterModels/pzuEnergyFiltersModel';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scannerg-prices-electric-pzu-table',
  templateUrl: './scannerg-prices-electric-pzu-table.component.html',
  styleUrls: ['./scannerg-prices-electric-pzu-table.component.scss']
})
export class ScannergPricesElectricPZUTableComponent extends LoadableComponentBase implements OnInit {

  filters: PzuEnergyFiltersModel;
  data;
  loading: boolean = false;

  constructor(private energyService: ElectricEnergyService) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.energyService
      .getEnergyPZUDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.data = entries;
        this.loading = false;
      });
  }
}
