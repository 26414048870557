import { Injectable } from "@angular/core";
import { UserForActivationDto } from "app/models/user/userForActivationDto";
import { UserForTableDto } from "app/models/user/userForTableDto";
import { Observable } from "rxjs";
import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private _apiUserUrl = ApiConstants.USER_API_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getUsers(): Observable<UserForTableDto[]> {
        return this.apiService.get<UserForTableDto[]>(`${this._apiUserUrl}/users`);
    }

    activateUser(user: UserForActivationDto): Observable<UserForTableDto[]> {
        return this.apiService.post<UserForTableDto[]>(`${this._apiUserUrl}/activateUser`, user);
    }

    deactivateUser(user: UserForActivationDto): Observable<UserForTableDto[]> {
        return this.apiService.post<UserForTableDto[]>(`${this._apiUserUrl}/deactivateUser`, user);
    }


}