import { Injectable } from "@angular/core";
import { DailyDataFiltersModel } from "app/models/scannergFilterModels/dailyDataFiltersModel";
import { DailyDataStackedFiltersModel } from "app/models/scannergFilterModels/dailyDataStackedFiltersModel";
import { MonthlyDataFiltersModel } from "app/models/scannergFilterModels/monthlyDataFiltersModel";
import { MonthlyDataStackedFiltersModel } from "app/models/scannergFilterModels/monthlyDataStackedFiltersModel";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ApiService } from "../shared/services/api.service";

@Injectable({
    providedIn: "root",
})
export class FilterService {
    private _apiUserUrl = ApiConstants.FILTER_ENDPOINT;

    constructor(private apiService: ApiService) { }

    getProducts(filters: DailyDataFiltersModel): Observable<string[]> {
        return this.apiService.post<string[]>(`${this._apiUserUrl}/products`, filters);
    }

    getMonthlyProducts(filters: MonthlyDataFiltersModel): Observable<string[]> {
        return this.apiService.post<string[]>(`${this._apiUserUrl}/monthlyProducts`, filters);
    }

    getDeliveryProfiles(filters: DailyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/deliveryProfiles`, filters);
    }

    getMonthlyDeliveryProfiles(filters: MonthlyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/monthlyDeliveryProfiles`, filters);
    }

    getPlatforms(filters: DailyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/platforms`, filters);
    }

    getMonthlyPlatforms(filters: MonthlyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/monthlyPlatforms`, filters);
    }

    getMarkets(filters: DailyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/markets`, filters);
    }

    getMonthlyMarkets(filters: MonthlyDataFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/monthlyMarkets`, filters);
    }

    getStackedMarkets(filters: DailyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedMarkets`, filters);
    }

    getStackedMonthlyMarkets(filters: MonthlyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedMarkets/monthlyData`, filters);
    }

    getStackedProducts(filters: DailyDataStackedFiltersModel): Observable<string[]> {
        return this.apiService.post<string[]>(`${this._apiUserUrl}/stackedProducts`, filters);
    }

    getStackedMonthlyProducts(filters: MonthlyDataStackedFiltersModel): Observable<string[]> {
        return this.apiService.post<string[]>(`${this._apiUserUrl}/stackedProducts/monthlyData`, filters);
    }

    getStackedDeliveryProfiles(filters: DailyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedDeliveryProfiles`, filters);
    }

    getStackedMonthlyDeliveryProfiles(filters: MonthlyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedDeliveryProfiles/monthlyData`, filters);
    }

    getStackedPlatforms(filters: DailyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedPlatforms`, filters);
    }

    getStackedMonthlyPlatforms(filters: MonthlyDataStackedFiltersModel): Observable<number[]> {
        return this.apiService.post<number[]>(`${this._apiUserUrl}/stackedPlatforms/monthlyData`, filters);
    }
}