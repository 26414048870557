import { ValueFormatterParams } from "ag-grid-community";
import { getUtcDateFromString } from "./MomentUtils";
import * as moment from "moment";
import { MomentConstants } from "./moment-constants";

export function formatDate(date: string, format?: string): string {
  if (!date) {
    return "N/A";
  }

  if (format) {
    return getUtcDateFromString(date).format(format);
  }
  return getUtcDateFromString(date).format(
    MomentConstants.PRETTY_MONTH_DAY_YEAR
  );
}

export function formatLocalTime(date: string, format?: string): string {
  if (!date) {
    return "N/A";
  }

  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format(
    MomentConstants.PRETTY_MONTH_DAY_YEAR
  );
}

export function DateFormatter(dateFormat: string = MomentConstants.PRETTY_MONTH_DAY_YEAR) {
  return (params: ValueFormatterParams) => {
    return formatDate(params.value, dateFormat);
  };
}

export function DecimalFormatter(numberOfDecimals: number = 0) {
  return (params: ValueFormatterParams) => {
    return formatDecimal(params.value, numberOfDecimals);
  };
}

export function PercentageFormatter(numberOfDecimals: number = 0) {
  return (params: ValueFormatterParams) => {
    return formatPercentage(params.value, numberOfDecimals);
  };
}

let numberStyles = new Map<number, object>();

export function formatDecimal(value: number, numberOfDecimals: number = 0): string {
  let numberStyle;

  if (numberStyles.has(numberOfDecimals)) {
    numberStyle = numberStyles.get(numberOfDecimals);
  }
  else {
    numberStyle = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals,
      useGrouping: true,
    });

    numberStyles.set(numberOfDecimals, numberStyle);
  }

  return numberStyle.format(value);
}

let decimalStyles = new Map<number, object>();

export function formatPercentage(value: number, numberOfDecimals: number = 0): string {
  let decimalStyle;

  if (decimalStyles.has(numberOfDecimals)) {
    decimalStyle = decimalStyles.get(numberOfDecimals);
  }
  else {
    decimalStyle = new Intl.NumberFormat('en-GB', {
      style: 'percent',
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals,
      useGrouping: true,
    });

    decimalStyles.set(numberOfDecimals, decimalStyle);
  }

  return decimalStyle.format(value);
}
