import { GridOptions } from 'ag-grid-community';
import { AgGridUtils } from './AgGridUtils';

export const gridOneLineHeight = 35;

export const getGridOptions: (options?: GridOptions) => GridOptions = (options?: GridOptions) => {
    const frameworkComponents = {};
    Object.assign(frameworkComponents, AgGridUtils.gridFilters);
    Object.assign(frameworkComponents, AgGridUtils.gridRenderers);
    return Object.assign({
        suppressMovableColumns: true,
        stopEditingWhenGridLosesFocus: true,
        animateRows: false,
        defaultColDef: {
            resizable: true,
            filter: true,
            sortable: true,
            editable: false,
        },
        multiSortKey: 'ctrl',
        rowSelection: 'single',
        frameworkComponents: frameworkComponents,
        headerHeight: 40,
        rowHeight: gridOneLineHeight,
        onGridReady: params => {
            setTimeout(_ => {
                params.api.resetRowHeights();
            }, 0);
        },
        onGridSizeChanged: e => {
            e.api.resetRowHeights();
            if (e.clientWidth > 0) {
                setTimeout(_ => {
                    e.api.sizeColumnsToFit();
                }, 0);
            }
        },
        onGridColumnsChanged: e => {
            e.api.resetRowHeights();
            setTimeout(_ => {
                e.api.sizeColumnsToFit();
            }, 0);
        },
        onColumnResized: e => {
            e.api.resetRowHeights();
        },
        onRowDataChanged: e => {
            e.api.resetRowHeights();
        },
        onRowDataUpdated: e => {
            e.api.resetRowHeights();
        }
    } as GridOptions, options);
};
