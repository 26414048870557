import { Component, OnInit } from '@angular/core';
import { Colours } from 'app/constants/colors';
import { ProducersModel } from 'app/models/producers/producersModel';
import { ProvidersModel } from 'app/models/providers/providersModel';
import { EnergyTimeButtonsFilter } from 'app/models/scannergFilterModels/energyFilterTimeButtonsModel';
import { ElectricEnergyService } from 'app/services/electric-energy.service';
import { NaturalGasService } from 'app/services/natural-gas.service';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'scannerg-providers-natural-gas',
  templateUrl: './scannerg-providers-natural-gas.component.html',
  styleUrls: ['./scannerg-providers-natural-gas.component.scss']
})
export class ScannergProvidersNaturalGasComponent extends GridComponentBase<ProvidersModel> implements OnInit {

  chartOption: EChartsOption;
  myChart: any;
  chartData: ProvidersModel[];
  period: number = 12;
  filters: EnergyTimeButtonsFilter;

  constructor(private gasService: NaturalGasService) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T")[0],
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('MM-YYYY')
        }
      },
      {
        field: "name",
        headerName: "Furnizor",
        width: 450,
        suppressSizeToFit: true,
      },
      {
        valueGetter: x => x.data.value != null ? x.data.value.toFixed(2) : 0,
        headerName: "Volum",
        width: 450,
        suppressSizeToFit: true,
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
    this.loadOneYearData();
  }

  private initChart() {
    let series = [];
    let dateDict = {};
    let productDict = {};
    this.chartData.forEach(element => {
      if (Array.isArray(productDict[element.name])) {
        productDict[element.name].push(element);
      }
      else {
        productDict[element.name] = [element];
      }
      if (Array.isArray(dateDict[element.date.split("T")[0].substring(0, 7)])) {
        dateDict[element.date.split("T")[0].substring(0, 7)].push(element)
      }
      else {
        dateDict[element.date.split("T")[0].substring(0, 7)] = [element]
      }
    })
    Object.entries(productDict).forEach(([key, value]: [string, ProducersModel[]]) => {
      series.push(
        {
          name: key,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: value.map(x => [moment(x.date).format('MM-YYYY'), x.value])
        },
      );
    })
    this.chartOption = {
      title: {
        text: "Furnizori gaze naturale",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
      },
      tooltip: this.period == 1 ? {
        trigger: "item",
      } : {
        trigger: "axis",
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        }
      },
      legend: {
        type: 'scroll',
        bottom: '0%',
        textStyle: {
          fontFamily: 'Arial',
          color: '#595959'
        },
        data: Object.keys(productDict)
      },
      toolbox: {
        top: 0,
        feature: this.period == 1 ? {} : {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: 'Furnizori gaze naturale'
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: this.period == 1 ? [] : [
        {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          data: Object.keys(dateDict).sort().map(x => moment(x).format('MM-YYYY'))
        },
      ],
      yAxis: this.period == 1 ? [] : [
        {
          type: 'value',
          axisLabel: {
            fontFamily: 'Arial',
            color: '#595959'
          },
          position: 'right',
          axisLine: {
            show: true,
          },
        },
      ],
      series: series,
      color: Colours.ColorPallete
    };
  }

  public loadOneYearData() {
    this.filters = {
      product: "",
      fromDate: -12,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasProviders(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.chartData = entries;
        this.data = entries;
        this.period = 12;
        this.initChart();
      });
  }
  public loadTwoYearsData() {
    this.filters = {
      product: "",
      fromDate: -24,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.gasService
      .getGasProviders(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.chartData = entries;
        this.data = entries;
        this.period = 24;
        this.initChart();
      });
  }

  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }
}
