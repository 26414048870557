import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NouisliderModule } from 'ng2-nouislider';
import { RouterModule } from '@angular/router';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog-page/blog/blog.component';
import { BlogPostComponent } from './blog-page/blog-post/blog-post.component';
import { BlogCardComponent } from './blog-page/blog-card/blog-card.component';
import { NotFoundComponent } from './404/404.component';
import { EventCardComponent } from './events/event-card/event-card.component';
import { EventsComponent } from './events/events/events.component';
import { NgbdModalComponent, NgbdModalContent } from 'app/components/modal/modal.component';
import { EventModalComponent } from './events/modals/event-modal/event-modal.component';
import { EventInfoModalContent } from './events/modals/event-modal-content/event-modal-content.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScannergEssentialComponent } from './scannerg/scannerg-essential/scannerg-essential.component';
import { ScannergPricesComponent } from './scannerg/scannerg-prices/scannerg-prices.component';
import { ScannergVolumesComponent } from './scannerg/scannerg-volumes/scannerg-volumes.component';
import { ScannergProvidersComponent } from './scannerg/scannerg-providers/scannerg-providers.component';
import { ScannergPressComponent } from './scannerg/scannerg-press/scannerg-press.component';
import { ScannergLegalComponent } from './scannerg/scannerg-legal/scannerg-legal.component';
import { ScannergAdvancedComponent } from './scannerg/scannerg-advanced-components/advanced/scannerg-advanced.component';
import { PdfCardComponent } from 'app/components/pdf-card/pdf-card.component';
import { EnergyGridComponent } from 'app/components/energy-grid/energy-grid.component';
import { EnergyChartComponent } from 'app/components/energy-chart/energy-chart.component';
import { ScannergAdvancedUniquePeriodComponent } from './scannerg/scannerg-advanced-components/unique-period/scannerg-advanced-unique-period.component';
import { ScannergAdvancedDailyDataUniquePeriodComponent } from './scannerg/scannerg-advanced-components/daily-data-unique-period/scannerg-advanced-daily-data-unique-period.component';
import { ScannergPricesElectricEnergyComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-energy/scannerg-prices-electric-energy.component';
import { ScannergAdvancedStackedPeriodComponent } from './scannerg/scannerg-advanced-components/stacked-period/scannerg-advanced-stacked-period.component';
import { ScannergPricesElectricEnergyTableComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-energy-table/scannerg-prices-electric-energy-table.component';
import { ScannergPricesNaturalGasTableComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas-table/scannerg-prices-natural-gas-table.component';
import { ScannergAdvancedPzuElectricUniquePeriodComponent } from './scannerg/scannerg-advanced-components/PZU-electric-energy/scannerg-advanced-pzu-electric-unique-period.component';
import { PzuEnergyGridComponent } from 'app/components/pzu-energy-grid/pzu-energy-grid.component';
import { PzuEnergyChartComponent } from 'app/components/pzu-energy-chart/pzu-energy-chart.component';
import { GasGridComponent } from 'app/components/gas-grid/gas-grid.component';
import { ScannergAdvancedDailyDataStackedPeriodComponent } from './scannerg/scannerg-advanced-components/daily-data-stacked-period/scannerg-advanced-daily-data-stacked-period.component';
import { StackedEnergyChartComponent } from 'app/components/stacked-energy-chart/stacked-energy-chart.component';
import { ScannergAdvancedPzuElectricStackedPeriodComponent } from './scannerg/scannerg-advanced-components/PZU-electric-energy-stacked-period/scannerg-advanced-pzu-electric-stacked-period.component';
import { PzuEnergyChartStackedComponent } from 'app/components/pzu-energy-chart-stacked/pzu-energy-chart-stacked.component';
import { ManageClientPageComponent } from './manage/clients-page/clients-page.component';
import { ButtonCellRendererComponent } from 'app/shared/components/ag-grid/button-cell-renderer/button-cell-renderer.component';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import { ActivateUserModalContent } from './manage/activate-user-modal-content/activate-user-modal-content.component';
import { ScannergAdvancedMonthlyDataUniquePeriodComponent } from './scannerg/scannerg-advanced-components/monthly-data-unique-period/scannerg-advanced-monthly-data-unique-period.component';
import { MonthlyEnergyChartComponent } from 'app/components/monthly-energy-chart/monthly-energy-chart.component';
import { ScannergAdvancedMonthlyDataStackedPeriodComponent } from './scannerg/scannerg-advanced-components/monthly-data-stacked-period/scannerg-advanced-monthly-data-stacked-period.component';
import { StackedMonthlyDataEnergyChartComponent } from 'app/components/stacked-monthly-data-energy-chart/stacked-monthly-data-energy-chart.component';
import { ManagePressAndLegalPageComponent } from './manage/admin-press-legal-page/admin-press-legal-page.component';
import { ScannergPricesElementEchartComponent } from './scannerg/scannerg-prices/scannerg-prices-element-electric-echart/scannerg-prices-element-echart.component';
import { ScannergPricesNaturalGasComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas/scannerg-prices-natural-gas.component';
import { ScannergPricesElementGasEchartComponent } from './scannerg/scannerg-prices/scannerg-prices-element-gas-echart/scannerg-prices-element-gas-echart.component';
import { ScannergPricesElectricPZUEchartComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-pzu-echart/scannerg-prices-electric-pzu-echart.component';
import { ScannergPricesElectricPZUTableComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-pzu-table/scannerg-prices-electric-pzu-table.component';
import { ScannergVolumesElectricEnergyComponent } from './scannerg/scannerg-volumes/scannerg-volumes-electric-energy/scannerg-volumes-electric-energy.component';
import { ScannergVolumesElectricEnergyImportExportComponent } from './scannerg/scannerg-volumes/scannerg-volumes-electric-energy-import-export/scannerg-volumes-electric-energy-import-export.component';
import { ScannergVolumesElectricEnergyMixProductieComponent } from './scannerg/scannerg-volumes/scannerg-volumes-electric-energy-mix-productie/scannerg-volumes-electric-mix-productie.component';
import { ScannergVolumesElectricEnergyProductionConsumeComponent } from './scannerg/scannerg-volumes/scannerg-volumes-electric-energy-production-consume/scannerg-volumes-electric-production-consume.component';
import { ScannergVolumesNaturalGasComponent } from './scannerg/scannerg-volumes/scannerg-volumes-natural-gas/scannerg-volumes-natural-gas.component';
import { ScannergVolumesNaturalGasImportExportComponent } from './scannerg/scannerg-volumes/scannerg-volumes-natural-gas-import-export/scannerg-volumes-natural-gas-import-export.component';
import { ScannergVolumesNaturalGasProductionConsumeComponent } from './scannerg/scannerg-volumes/scannerg-volumes-natural-gas-production-consume/scannerg-volumes-natural-gas-production-consume.component';
import { ScannergVolumesNaturalGasInmagazinareComponent } from './scannerg/scannerg-volumes/scannerg-volumes-natural-gas-inmagazinare/scannerg-volumes-natural-gas-inmagazinare.component';
import { ScannergVolumesNaturalGasProducersComponent } from './scannerg/scannerg-volumes/scannerg-volumes-natural-gas-producers/scannerg-volumes-natural-gas-producers.component';
import { ScannergVolumesElectricEnergyProducersComponent } from './scannerg/scannerg-volumes/scannerg-volumes-electric-energy-producers/scannerg-volumes-electric-energy-producers.component';
import { ScannergProvidersElectricEnergyComponent } from './scannerg/scannerg-providers/scannerg-providers-electric-energy/scannerg-providers-electric-energy.component';
import { ScannergProvidersNaturalGasComponent } from './scannerg/scannerg-providers/scannerg-providers-natural-gas/scannerg-providers-natural-gas.component';
import { EuropePzuValuesComponent } from './europe-pzu-values/europe-pzu-values.component';
import { PzuNaturalGasGridComponent } from 'app/components/pzu-natural-gas-grid/pzu-natural-gas-grid.component';
import { Co2EmissionsChartComponent } from 'app/components/co2-emissions-chart/co2-emissions-chart.component';
import { Co2EmissionsGridComponent } from 'app/components/co2-emissions-grid/co2-emissions-grid.component';
import { EchilibrareEnergyChartComponent } from 'app/components/echilibrare-energy-chart/echilibrare-energy-chart.component';
import { EchilibrareEnergyGridComponent } from 'app/components/echilibrare-energy-grid/echilibrare-energy-grid.component';
import { EchilibrareNaturalGasChartComponent } from 'app/components/echilibrare-natural-gas-chart/echilibrare-natural-gas-chart.component';
import { EchilibrareNaturalGasGridComponent } from 'app/components/echilibrare-natural-gas-grid/echilibrare-natural-gas-grid.component';
import { ImportExportEnergyChartComponent } from 'app/components/import-export-energy-chart/import-export-energy-chart.component';
import { ImportExportEnergyGridComponent } from 'app/components/import-export-energy-grid/import-export-energy-grid.component';
import { ImportExportNaturalGasChartComponent } from 'app/components/import-export-natural-gas-chart/import-export-natural-gas-chart.component';
import { ImportExportNaturalGasGridComponent } from 'app/components/import-export-natural-gas-grid/import-export-natural-gas-grid.component';
import { IntrazilnicEnergyChartComponent } from 'app/components/intrazilnic-energy-chart/intrazilnic-energy-chart.component';
import { IntrazilnicEnergyGridComponent } from 'app/components/intrazilnic-energy-grid/intrazilnic-energy-grid.component';
import { IntrazilnicNaturalGasChartComponent } from 'app/components/intrazilnic-natural-gas-chart/intrazilnic-natural-gas-chart.component';
import { IntrazilnicNaturalGasGridComponent } from 'app/components/intrazilnic-natural-gas-grid/intrazilnic-natural-gas-grid.component';
import { PetrolChartComponent } from 'app/components/petrol-chart/petrol-chart.component';
import { PetrolGridComponent } from 'app/components/petrol-grid/petrol-grid.component';
import { PzuNaturalGasChartComponent } from 'app/components/pzu-natural-gas-chart/pzu-natural-gas-chart.component';
import { ScannergPricesCo2Component } from './scannerg/scannerg-prices/scannerg-prices-co2/scannerg-prices-co2.component';
import { ScannergPricesElectricEnergyEchilibrareComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-energy-echilibrare/scannerg-prices-electric-energy-echilibrare.component';
import { ScannergPricesElectricEnergyImportExportComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-energy-import-export/scannerg-prices-electric-energy-import-export.component';
import { ScannergPricesElectricEnergyIntrazilnicComponent } from './scannerg/scannerg-prices/scannerg-prices-electric-energy-intrazilnic/scannerg-prices-electric-energy-intrazilnic.component';
import { ScannergPricesNaturalGasEchilibrareComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas-echilibrare/scannerg-prices-natural-gas-echilibrare.component';
import { ScannergPricesNaturalGasImportExportComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas-import-export/scannerg-prices-natural-gas-import-export.component';
import { ScannergPricesNaturalGasIntrazilnicComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas-intrazilnic/scannerg-prices-natural-gas-intrazilnic.component';
import { ScannergPricesNaturalGasPzuComponent } from './scannerg/scannerg-prices/scannerg-prices-natural-gas-pzu/scannerg-prices-natural-gas-pzu.component';
import { ScannergPricesPetrolComponent } from './scannerg/scannerg-prices/scannerg-prices-petrol/scannerg-prices-petrol.component';
import { NgxLoadingModule } from 'ngx-loading';
import { PdfCardAdminComponent } from 'app/components/pdf-card-admin/pdf-card-admin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ExtraParamsGridComponent } from 'app/components/extra-params-grid/extra-params-grid.component';
import { MonthlyEnergyGridComponent } from 'app/components/monthly-energy-grid/monthly-energy-grid.component';
import { MonthlyExtraParamsGridComponent } from 'app/components/monthly-extra-params-grid/monthly-extra-params-grid.component';
import { MonthlyGasGridComponent } from 'app/components/monthly-gas-grid/monthly-gas-grid.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ScannergSolutionComponent } from './scannerg-solution/scannerg-solution.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { PlannergLandingComponent } from './plannerg-landing/plannerg-landing.component';
import { GreenergLandingComponent } from './greenerg-landing/greenerg-landing.component';
import { TrainergLandingComponent } from './trainerg-landing/trainerg-landing.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        AgGridModule.withComponents([
            ButtonCellRendererComponent,
            GridComponentBase
        ]),
        NgMultiSelectDropDownModule.forRoot(),
        NgxLoadingModule.forRoot({}),
        CommonModule,
        ClipboardModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        ReactiveFormsModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        SigninComponent,
        ForgotPasswordComponent,
        AboutUsComponent,
        ScannergAdvancedComponent,
        ContactComponent,
        BlogComponent,
        BlogPostComponent,
        BlogCardComponent,
        NotFoundComponent,
        EventCardComponent,
        EventsComponent,
        NgbdModalComponent,
        NgbdModalContent,
        ScannergPricesNaturalGasTableComponent,
        EventModalComponent,
        EventInfoModalContent,
        ScannergEssentialComponent,
        ScannergPricesComponent,
        ScannergVolumesComponent,
        ScannergProvidersComponent,
        ScannergPressComponent,
        ScannergLegalComponent,
        PdfCardComponent,
        ScannergPricesElementGasEchartComponent,
        EnergyGridComponent,
        MonthlyGasGridComponent,
        MonthlyEnergyGridComponent,
        MonthlyExtraParamsGridComponent,
        EnergyChartComponent,
        ExtraParamsGridComponent,
        ScannergAdvancedUniquePeriodComponent,
        ScannergAdvancedDailyDataUniquePeriodComponent,
        ScannergPricesElectricEnergyComponent,
        ScannergAdvancedStackedPeriodComponent,
        ScannergAdvancedPzuElectricUniquePeriodComponent,
        PzuEnergyGridComponent,
        PzuNaturalGasGridComponent,
        PzuEnergyChartComponent,
        Co2EmissionsChartComponent,
        Co2EmissionsGridComponent,
        PetrolGridComponent,
        PetrolChartComponent,
        PzuNaturalGasChartComponent,
        IntrazilnicEnergyChartComponent,
        IntrazilnicEnergyGridComponent,
        EchilibrareEnergyGridComponent,
        EchilibrareEnergyChartComponent,
        ImportExportEnergyChartComponent,
        ImportExportEnergyGridComponent,
        ImportExportNaturalGasGridComponent,
        ImportExportNaturalGasChartComponent,
        IntrazilnicNaturalGasChartComponent,
        IntrazilnicNaturalGasGridComponent,
        EchilibrareNaturalGasGridComponent,
        EchilibrareNaturalGasChartComponent,
        GasGridComponent,
        ScannergAdvancedDailyDataStackedPeriodComponent,
        ScannergAdvancedStackedPeriodComponent,
        ScannergPricesElectricEnergyTableComponent,
        ScannergPricesElementEchartComponent,
        ScannergPricesNaturalGasComponent,
        ScannergPricesNaturalGasTableComponent,
        StackedEnergyChartComponent,
        ScannergAdvancedPzuElectricStackedPeriodComponent,
        PzuEnergyChartStackedComponent,
        ManageClientPageComponent,
        ActivateUserModalContent,
        ScannergAdvancedMonthlyDataUniquePeriodComponent,
        MonthlyEnergyChartComponent,
        ScannergPricesElectricPZUEchartComponent,
        ScannergAdvancedMonthlyDataStackedPeriodComponent,
        StackedMonthlyDataEnergyChartComponent,
        ManagePressAndLegalPageComponent,
        ScannergPricesElectricPZUTableComponent,
        ScannergVolumesElectricEnergyComponent,
        ScannergVolumesElectricEnergyImportExportComponent,
        ScannergVolumesElectricEnergyMixProductieComponent,
        ScannergVolumesElectricEnergyProductionConsumeComponent,
        ScannergVolumesNaturalGasComponent,
        ScannergVolumesNaturalGasImportExportComponent,
        ScannergVolumesNaturalGasProductionConsumeComponent,
        ScannergVolumesNaturalGasInmagazinareComponent,
        ScannergVolumesNaturalGasProducersComponent,
        ScannergVolumesElectricEnergyProducersComponent,
        ScannergProvidersElectricEnergyComponent,
        ScannergProvidersNaturalGasComponent,
        EuropePzuValuesComponent,
        ScannergPricesNaturalGasPzuComponent,
        ScannergPricesElectricEnergyIntrazilnicComponent,
        ScannergPricesElectricEnergyEchilibrareComponent,
        ScannergPricesElectricEnergyImportExportComponent,
        ScannergPricesNaturalGasIntrazilnicComponent,
        ScannergPricesNaturalGasEchilibrareComponent,
        ScannergPricesNaturalGasImportExportComponent,
        ScannergPricesCo2Component,
        ScannergPricesPetrolComponent,
        PdfCardAdminComponent,
        ResetPasswordComponent,
        ScannergSolutionComponent,
        SolutionsComponent,
        PlannergLandingComponent,
        GreenergLandingComponent,
        TrainergLandingComponent,

    ],
    entryComponents: [EventInfoModalContent, ActivateUserModalContent],
})
export class ExamplesModule { }
