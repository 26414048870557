export function clone(data) {
    return data != null ? JSON.parse(JSON.stringify(data)) : data;
}

export function compareEntitiesById(obj1: any, obj2: any) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
}

export function createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // tslint:disable-next-line:no-bitwise
        const r = Math.random() * 16 | 0;
        // tslint:disable-next-line:no-bitwise
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function sortNumbersArray(data: number[]) {
    data.sort((v1, v2) => v1 > v2 ? -1 : 1);
}

export function compareStringsIgnoreCase(s1: string, s2: string): number {
    const v1 = s1 ? s1.toLowerCase() : '';
    const v2 = s2 ? s2.toLowerCase() : '';
    return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
}

export function dateComparator(date1, date2) {
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }
  
    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }
  
    return date1Number - date2Number;
  }
