import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { FooterComponent } from './shared/components/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { NavbarTransparentComponent } from './shared/components/navbar-transparent/navbar-transparent.component';
import { LayoutDefaultNavbarComponent } from './shared/components/layout-default-navbar/layout-default-navbar.component';
import { NavbarScannergComponent } from './shared/components/navbar-scannerg/navbar-scannerg.component';
import { LayoutScannergComponent } from './shared/components/layout-scannerg/layout-scannerg.component';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { LayoutAuthenticationComponent } from './shared/components/layout-authentication/layout-authentication.component';
import { FooterScannergComponent } from './shared/components/footer-scannerg/footer-scannerg.component';
import { AgGridModule } from 'ag-grid-angular';
import { LayoutManageComponent } from './shared/components/layout-manage/layout-manage.component';
import { NavbarManageComponent } from './shared/components/navbar-manage/navbar-manage.component';
import { NavbarTransparentScannergComponent } from './shared/components/navbar-transparent-scannerg/navbar-transparent-scannerg.component';
import { LayoutTransparentScannergComponent } from './shared/components/layout-transparent-scannerg/layout-transparent-scannerg.component';
import { LayoutAuthenticationComponentSignIn } from './shared/components/layout-authentication-signin/layout-authentication-signin.component';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    FooterScannergComponent,
    LayoutComponent,
    NavbarTransparentComponent,
    LayoutDefaultNavbarComponent,
    NavbarScannergComponent,
    LayoutScannergComponent,
    LayoutAuthenticationComponent,
    LayoutManageComponent,
    LayoutAuthenticationComponentSignIn,
    NavbarTransparentScannergComponent,
    LayoutTransparentScannergComponent,
    NavbarManageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:5001"],
        disallowedRoutes: []
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
