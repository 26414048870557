import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalPressDto } from 'app/models/legalPress/legalPressDto';
import { DialogService } from 'app/services/dialog.service';
import { LegalPressService } from 'app/services/legal-press.service';
import { UserService } from 'app/services/user.service';
import { LoadableComponentBase } from 'app/shared/components/ag-grid/LoadableComponentBase';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'scannerg-admin-press-legal-page',
  templateUrl: './admin-press-legal-page.component.html',
  styleUrls: ['./admin-press-legal-page.component.scss']
})

export class ManagePressAndLegalPageComponent extends LoadableComponentBase {
  focus;
  fields;
  pdfFile: any;
  newLegalPress: LegalPressDto;
  filterForm: FormGroup;

  constructor(
    private legalPressService: LegalPressService,
    private dialogService: DialogService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      title: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
    })
    this.loadData();
  }

  loadData() {
    this.legalPressService
      .getAllLegalPress()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.fields = entries;
      });
  }

  updatePDF(files: any) {
    this.pdfFile = files;
  }

  addPdfFile() {
    let fileToUpload = <File>this.pdfFile;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    // this.benefitService.addBenefitLogo(benefitWithoutLogo.id, formData).subscribe(benefitWithLogo => {
    //   this.updateGridAfterAdd(benefitWithLogo);
    //   this.gridOptions.api.deselectAll();
    //   this.currentSelection = undefined;
    //   this.dialogService.showSuccessMessage('Success!', 'Benefit added successfully.');
    //   this._onHide();
    // });
  }

  addNewLegalPress() {
    let fileToUpload = <File>this.pdfFile[0];
    this.newLegalPress = {
      id: 0,
      date: '2022-02-02',
      title: this.filterForm.get("title").value,
      description: this.filterForm.get("description").value,
      path: fileToUpload.name
    }

    this.legalPressService.addLegalPress(this.newLegalPress)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(legalPressWihtoutFile => {


        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        this.legalPressService.addLegalPressFile(legalPressWihtoutFile.id, formData).subscribe(legalPress => {
          this.dialogService.showSuccessMessage('Success!', 'Fișierul a fost adăugat.');
        });
      });
  }
}

