import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LegalPressDto } from 'app/models/legalPress/legalPressDto';

@Component({
    selector: 'app-pdf-card',
    templateUrl: './pdf-card.component.html',
    styleUrls: ['./pdf-card.component.scss']
})

export class PdfCardComponent implements OnInit {

    @Output() showMorePressed = new EventEmitter();
    @Input() inputData: LegalPressDto;

    constructor(private router: Router) {

    }
    ngOnInit() { }

    onClickBlogCard() {
        this.router.navigateByUrl('/blog-post');
    }

    moreInfoClicked() {
        window.open("api/legalPress/" + this.inputData.path, '_blank');
        // window.open(this.inputData.path, '_blank');
    }
}
