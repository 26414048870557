import { Component, Input, OnInit } from "@angular/core";
import { ElectricEnergyService } from "app/services/electric-energy.service";
import { LoadableComponentBase } from "app/shared/components/ag-grid/LoadableComponentBase";
import { EChartsOption } from "echarts";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-scannerg-prices-electric-pzu-echart",
  templateUrl: "./scannerg-prices-electric-pzu-echart.component.html",
  styleUrls: ["./scannerg-prices-electric-pzu-echart.component.scss"],
})
export class ScannergPricesElectricPZUEchartComponent
  extends LoadableComponentBase
  implements OnInit {
  @Input() productName;
  energyPZUDictData = { Volum: [], Price: [], Date: [] };

  chartOption: EChartsOption;
  filters: any;
  loading: boolean = false;
  toNumbers = (arr) => arr.map(Number);
  toDate = (arr) => arr.map(x => moment(x).format("DD-MM-YYYY"));

  constructor(private energyService: ElectricEnergyService) {
    super();
  }

  ngOnInit(): void { }

  private initChart() {
    console.log(this.toDate(this.energyPZUDictData["Date"]));
    console.log("fone");

    this.chartOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["Price", "Volum"],
      },
      toolbox: {
        feature: {
          restore: {},
          magicType: {
            type: ["line", "bar"],
          },
        },
      },
      grid: {
        left: "5%",
        right: "8%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: this.toDate(this.energyPZUDictData["Date"]),
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Price",
          position: "left",
        },
        {
          type: "value",
          name: "Volum",
          position: "right",
        },
      ],
      series: [
        {
          name: "Price",
          type: "line",
          data: this.toNumbers(this.energyPZUDictData["Price"]),
        },
        {
          name: "Volum",
          type: "line",
          data: this.toNumbers(this.energyPZUDictData["Volum"]),
        },
      ],
    };
  }

  public loadOneMonthData() {
    this.loading = true;
    this.filters = {
      product: this.productName,
      fromDate: -1,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyPZUDictData["Volum"] = entries.Volum;
        this.energyPZUDictData["Price"] = entries.BidPrice;
        this.energyPZUDictData["Date"] = entries.Date;
        this.initChart();
        this.loading = false;
      });
  }

  public loadThreeMonthsData() {
    this.loading = true;
    this.filters = {
      product: this.productName,
      fromDate: -3,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyPZUDictData["Volum"] = entries.Volum;
        this.energyPZUDictData["Price"] = entries.BidPrice;
        this.energyPZUDictData["Date"] = entries.Date;
        this.initChart();
        this.loading = false;
      });
  }

  public loadOneYearData() {
    this.loading = true;
    this.filters = {
      product: this.productName,
      fromDate: -12,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyPZUDictData["Volum"] = entries.Volum;
        this.energyPZUDictData["Price"] = entries.BidPrice;
        this.energyPZUDictData["Date"] = entries.Date;
        this.initChart();
        this.loading = false;
      });
  }
  public loadTwoYearsData() {
    this.loading = true;
    this.filters = {
      product: this.productName,
      fromDate: -24,
      volume: 0,
      buyPrice: 0,
      sellPrice: 0,
    };
    this.energyService
      .getEnergyDataChart(this.filters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entries) => {
        this.energyPZUDictData["Volum"] = entries.Volum;
        this.energyPZUDictData["Price"] = entries.BidPrice;
        this.energyPZUDictData["Date"] = entries.Date;
        this.initChart();
        this.loading = false;
      });
  }
}
