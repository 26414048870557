import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { PzuElectricEnergyDto } from 'app/models/electricEnergy/pzuElectricEnergyDto';
import { StackedPzuElectricEnergyDto } from 'app/models/electricEnergy/stackedPzuElectricEnergyDto';
import { Colours } from 'app/constants/colors';


@Component({
  selector: 'app-pzu-energy-chart-stacked',
  templateUrl: './pzu-energy-chart-stacked.component.html',
  styleUrls: ['./pzu-energy-chart-stacked.component.scss']
})
export class PzuEnergyChartStackedComponent implements OnInit {

  @Input() inputData;
  chartOption: EChartsOption;
  myChart: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData = changes.inputData.currentValue
    if (!changedData) return;
    let calculatedSeries = [];
    let dateDict = {};
    let productList = [];
    changedData.forEach((element: StackedPzuElectricEnergyDto) => {

      element.entries.forEach(entry => {
        if (Array.isArray(this.formatDayMonthHour(entry.date))) {
          dateDict[this.formatDayMonthHour(entry.date)].push(entry)
        }
        else {
          dateDict[this.formatDayMonthHour(entry.date)] = [entry]
        }
      });
      const priceName = `Preț ${element.year}`
      const volumeName = `Volum ${element.year}`
      productList.push(priceName);
      productList.push(volumeName);
      calculatedSeries.push(
        {
          name: priceName,
          type: 'line',
          // stack: 'Total',
          // areaStyle: {},
          // emphasis: {
          //   focus: 'series'
          // },
          data: element.entries.map(entry => [this.formatDayMonthHour(entry.date), entry.price])
        },
      )
      calculatedSeries.push(
        {
          name: volumeName,
          type: 'bar',
          // stack: 'Total',
          // areaStyle: {},
          // emphasis: {
          //   focus: 'series'
          // },
          // emphasis: {
          //   focus: 'series'
          // },
          yAxisIndex: 1,
          data: element.entries.map(entry => [this.formatDayMonthHour(entry.date), entry.volume])
        },
      )
    })

    this.chartOption = {
      title: {
        text: 'Scannerg-i'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: 'scroll',
        bottom: '1%',
        data: productList
      },
      toolbox: {
        feature: {
          restore: {
            title: '',
          },
          magicType: {
            type: ["line", "bar"],
            title: { 'line': '', "bar": '' }
          },
          saveAsImage: {
            title: '',
            name: 'Grafic'
          },
        },
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "17%",
        containLabel: true
      },
      dataZoom: [
        {
          left: "7%",
          right: "10%",
          bottom: 40,
          backgroundColor: '#B5F1FF',
          dataBackground: {
            lineStyle: {
              color: '#003A47'
            },
            // areaStyle: {
            //   color: '#00758F'
            // }
          },
          borderColor: '#003A47',
          fillerColor: '#47C6A3'
        },
        {
          type: "inside"
        }
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: Object.keys(dateDict).sort(this.compareItems)
        }
      ],
      yAxis: [
        {
          type: 'value',
          nameTextStyle: {
            fontFamily: "Arial"
          },
          name: 'Preț',
          position: 'right',
          axisLine: {
            show: true,
          },
        },
        {
          type: 'value',
          name: 'Volum',
          nameTextStyle: {
            fontFamily: "Arial"
          },
          position: 'left',
          axisLine: {
            show: true,
          },
        },
      ],
      series: calculatedSeries,
      color: Colours.ColorPallete
    };
  }

  private formatDayMonthHour(input: string) {
    const splittedDate = input.split("T");
    const days = splittedDate[0].split("-");
    const hours = splittedDate[1].split(":");

    return days[2] + "-" + days[1] + " " + hours[0] + ":" + hours[1];
  }

  private compareItems(item1, item2) {
    const item1Date = item1.split(" ");
    const item2Date = item2.split(" ");
    const item1Day = item1Date[0].split("-")[0]
    const item1Month = item1Date[0].split("-")[1]
    const item2Day = item2Date[0].split("-")[0]
    const item2Month = item2Date[0].split("-")[1]

    if (item1Month < item2Month) {
      return -1;
    }
    if (item1Month > item2Month) {
      return 1;
    }
    if (item1Month == item2Month) {
      if (item1Day < item2Day) {
        return -1
      }
      if (item1Day < item2Day) {
        return 1
      }
      if (item1Day == item2Day) {
        if (item1Date[1] < item2Date[1]) {
          return -1;
        }
        if (item1Date[1] > item2Date[1]) {
          return 1;
        }
      }
    }
    return 0;
  }
}
