import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventInfoModalContent } from '../event-modal-content/event-modal-content.component';

@Component({
    selector: 'app-modal-event',
    templateUrl: './event-modal.component.html'
})
export class EventModalComponent implements OnInit {
    @Input() event: any;
    constructor(private modalService: NgbModal) {
    }
    ngOnInit(): void {
    }
    open() {
        const modalRef = this.modalService.open(EventInfoModalContent);
        modalRef.componentInstance.name = 'World';
    }
}
