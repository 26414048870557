import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MonthlyElectricEnergyQuotesDto } from 'app/models/electricEnergy/monthlyElectricEnergyQuotesDto';
import { AgGridUtils } from 'app/shared/components/ag-grid/AgGridUtils';
import { GridComponentBase } from 'app/shared/components/ag-grid/GridComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-echilibrare-energy-grid',
  templateUrl: './echilibrare-energy-grid.component.html',
  styleUrls: ['./echilibrare-energy-grid.component.scss']
})
export class EchilibrareEnergyGridComponent extends GridComponentBase<MonthlyElectricEnergyQuotesDto> implements OnInit {

  @Input() isGas;
  @Input() inputData;

  constructor() {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);
    this.addColumnDefs([
      {
        valueGetter: x => x.data.date.split("T").join(" "),
        headerName: "Data",
        width: 150,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return moment(data.value).format('MM-YYYY')
        }
      },
      {
        field: "volumDeficitEchilibrare",
        headerName: "Volum Deficit",
        width: 200,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },
      {
        field: "pretMediuDeficitEchilibrare",
        headerName: "Pret Deficit",
        width: 170,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },
      {
        field: "volumExcedentEchilibrare",
        headerName: "Volum Excedent",
        width: 170,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },
      {
        field: "pretMediuExcedentEchilibrare",
        headerName: "Pret Excedent",
        width: 170,
        suppressSizeToFit: false,
        cellRenderer: (data) => {
          return data.value.toFixed(2)
        }
      },

    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No entries.</span>';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    var changedData: MonthlyElectricEnergyQuotesDto[] = changes.inputData.currentValue
    if (!changedData) return;
    this.data = changedData;
  }


  onRowSelected($event: any): void {
    throw new Error('Method not implemented.');
  }
  onRowDoubleClicked(_: any): void {
    throw new Error('Method not implemented.');
  }
  validateModel(prop: string): string {
    throw new Error('Method not implemented.');
  }
  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }

}
